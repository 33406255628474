import React, {useContext, useEffect, useState} from "react";
import {apiEVDashboardCardData, apiGetAllGroups} from "../../crud";
import {Formik} from "formik";
import DatePicker from 'react-datepicker';
import {DashboardDonutChartCard} from "./cards/DashboardDonutChartCard";
import {defaultGroups} from "../../Common/generic/table/InitialStates";
import AsyncSelect from "react-select/async";
import {DashboardLineChartCard} from "./cards/DashboardLineChartCard";
import {DashboardBarChartCard} from "./cards/DashboardBarChartCard";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {DashboardAlertsCard} from "./cards/DashboardAlertsCard";

export function NewDashboardPage({history, orgType}) {

    const [reload, setReload] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [data, setData] = useState({});
    const [allGroups, setAllGroups] = useState(defaultGroups);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const {isAdmin} = useContext(AuthContext);

    function reloadData() {
        setReload(reload + 1);
    }

    useEffect(() => {

        apiEVDashboardCardData({}).then(({data}) => {
            setData(data)
        })
            .catch(() => {
            });

    }, []);

    useEffect(() => {

        apiGetAllGroups().then(({data}) => {
            setAllGroups(data.entities);
        }).catch(() => {
        });

    }, []);

    const handleDonutClick = (seriesName) => {
        let groups = "";
        for (const group in selectedGroup) {
            groups = groups + selectedGroup[group].id + ",";
        }

        history.push(`/viewMore?groups=${groups}&title=${seriesName}&date=${selectedDate.toISOString()}&seriesName=${seriesName}&orgType=${orgType}`);
    }

    return (<>
            <div id="kt_app_content" className="app-content flex-column-fluid">

                <div className="py-5 h-auto mb-12">
                    <Formik
                        initialValues={{}}
                        onSubmit={(values) => {

                            let groups = "";
                            for (const group in selectedGroup) {
                                groups = groups + selectedGroup[group].id + ",";
                            }

                            let params = {
                                groups: groups, date: selectedDate
                            };

                            apiEVDashboardCardData(params).then(({data}) => {
                                setData(data)
                            })
                                .catch(() => {
                                });

                            reloadData()
                        }}
                    >
                        {({
                              values, handleSubmit, handleBlur, handleChange, setFieldValue,
                          }) => (<form onSubmit={handleSubmit} className="form form-label-right col-sm-12">
                            <div className="form-group row d-flex justify-content-end">
                                {isAdmin() && <div className="col-lg-4">
                                    <AsyncSelect
                                        isMulti={true}
                                        id="group"

                                        defaultOptions={allGroups}
                                        value={selectedGroup}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        onChange={(e) => {
                                            setSelectedGroup(e);
                                            handleSubmit();
                                        }}
                                    />

                                    <small className="form-text text-muted">
                                        <b>Filter</b> by Group
                                    </small>
                                </div>}

                                <div className="col-lg-2">
                                    <DatePicker className="form-control"
                                                selected={selectedDate}
                                                dateFormat="dd MMM yyyy"
                                                onChange={(e) => {
                                                    setSelectedDate(e);
                                                    handleSubmit();
                                                }}
                                    />

                                    <small className="form-text text-muted">
                                        <b>Filter</b> by Date
                                    </small>
                                </div>
                            </div>
                        </form>)}
                    </Formik>
                </div>

                <div className="row gx-5 gx-xl-10">
                    <div className="col-xl-6 mb-6">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            {Object.keys(data).length > 0 &&
                                <DashboardDonutChartCard title={"Vehicle Status"} data={data.vehicle_status}
                                                         onDonutClick={handleDonutClick}/>}
                        </div>
                    </div>

                    <div className="col-xl-6 mb-6">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>

                            {Object.keys(data).length > 0 && data.distance_travelled && data.distance_travelled.data ? (
                                <DashboardLineChartCard title={"Distance Travelled"} data={data.distance_travelled}
                                                        onViewMore={handleDonutClick} unit={"kms"}/>) : (
                                <div style={{textAlign: 'center'}}>No data available</div>)}

                        </div>
                    </div>

                </div>

                <div className="row gx-5 gx-xl-10">
                    <div className="col-xl-6 mb-6">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            {Object.keys(data).length > 0 && data.mvid_vehicles && data.mvid_vehicles.data ? (
                                <DashboardBarChartCard title={"Moving/Idle Vehicles"} data={data.mvid_vehicles}
                                                       onViewMore={handleDonutClick} unit={"mins"}/>) : (
                                <div style={{textAlign: 'center'}}>No data available</div>)}
                        </div>
                    </div>

                    <div className="col-xl-6 mb-6">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            {Object.keys(data).length > 0 && data.over_speeding_vehicles && data.over_speeding_vehicles.data ? (
                                <DashboardBarChartCard title={"Over Speeding Vehicles"}
                                                       data={data.over_speeding_vehicles}
                                                       onViewMore={handleDonutClick} unit={"Vehicles"}/>) : (
                                <div style={{textAlign: 'center'}}>No data available</div>)}
                        </div>
                    </div>
                </div>

                <div className="row gx-5 gx-xl-10">
                    <div className="col-xl-12 mb-12">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <DashboardAlertsCard title={"Vehicle Alerts"} history={history} reload={reload} selectedDate={selectedDate} selectedGroup={selectedGroup}/>
                        </div>
                    </div>
                </div>


                {orgType === "EV" && <div className="row gx-5 gx-xl-10">
                    <div className="col-xl-6 mb-6">
                        <div className="card card-flush h-md-100 p-6"
                             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            {Object.keys(data).length > 0 &&
                                <DashboardDonutChartCard title={"Battery Status"} data={data.battery_status}
                                                         onDonutClick={handleDonutClick}/>}
                        </div>
                    </div>
                </div>}

                {/*<div className="row gx-5 gx-xl-10">*/}
                {/*    <div className="col-xl-6 mb-6">*/}
                {/*        <div className="card card-flush h-md-100 p-6"*/}
                {/*             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>*/}
                {/*            {Object.keys(data).length > 0 && <DashboardBarChartCard title={"Alerts"} data={data.alerts}*/}
                {/*                                                                    onViewMore={handleDonutClick}/>}</div>*/}
                {/*    </div>*/}

                {/*    <div className="col-xl-6 mb-6">*/}
                {/*        <div className="card card-flush h-md-100 p-6"*/}
                {/*             style={{display: 'flex', flexDirection: 'column', height: '100%'}}>*/}
                {/*            {Object.keys(data).length > 0 &&*/}
                {/*                <DashboardBarChartCard title={"GeoFence Alerts"} data={data.geofence}*/}
                {/*                                       onViewMore={handleDonutClick}/>}</div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </>


    )

}
