import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {isMobile} from "react-device-detect";
import {GEOFENCE} from "../../crud";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {ActionsColumnFormatter} from "../../Common/column-formatters/ActionsColumnFormatter";

export function GeoFenceCard({history, reload}) {

    const [tableFilterData, setTableFilterData] = useState(0);

    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);


    useEffect(() => {
        setTitleButtonGotoCallback(newUserButtonClick, "NEW GEO-FENCE");
    }, [auth]);

    function newUserButtonClick() {
        history.push("/geofence/new");
    }

    function filter(filterData) {
        setTableFilterData(filterData);
    }

    function openEditDialog(id) {
        history.push(`/geofence/edit/${id}`);
    }

    function openTagDialog(id) {
        history.push(`/geofence/${id}/tagImei`);
    }

    function openDeleteDialog(id) {
        history.push(`/geofence/${id}/delete`);
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            sort: false,
            hidden: true
        },
        {
            dataField: "name",
            text: "Geo Fence Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "range",
            text: "Tracked Range",
            sort: false,
            formatter: (cell) => `${cell} Meters`,
        },
        {
            dataField: "detectMoveIn",
            text: "Detect Move In",
            sort: false,
        },
        {
            dataField: "detectMoveOut",
            text: "Detect Move Out",
            sort: false,
        },
        {
            dataField: "totalTaggedVehicles",
            text: "Tagged Vehicles",
            sort: false,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                buttons:
                    [{
                        name: "Attach IMEI",
                        color: "warning",
                        icon: "/media/svg/icons/Communication/Share.svg",
                        method: openTagDialog
                    },
                        {
                            name: "Edit",
                            color: "primary",
                            icon: "/media/svg/icons/Communication/Write.svg",
                            method: openEditDialog
                        },
                        {
                            name: "Delete",
                            color: "danger",
                            icon: "/media/svg/icons/Home/Trash.svg",
                            method: openDeleteDialog
                        }
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            hidden: isMobile
        },
    ];

    return (
        <Card>
            <CardBody>
                <GenericTable reload={reload}
                              tableFilterData={tableFilterData}
                              defaultData={defaultList}
                              columns={columns}
                              url={GEOFENCE}
                />
            </CardBody>
        </Card>
    );
}
