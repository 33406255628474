import React, {useState} from "react";
import {OrgsCard} from "./OrgsCard";
import {Route} from "react-router-dom";
import {OrgEditDialog} from "./OrgEditDialog";


export function OrgsPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/admin/orgs/new">
                {({history, match}) => (
                    <OrgEditDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push("/admin/orgs");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/orgs/:id/edit">
                {({history, match}) => (
                    <OrgEditDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/admin/orgs");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <OrgsCard history={history} reload={reload}/>
        </>
    );
}
