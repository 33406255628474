import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {MapContainer, Marker, Popup, useMap} from 'react-leaflet';
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import {apiGetLatestLocationLog} from "../../crud";
import {defaultLocationLog} from "../../Common/generic/table/InitialStates";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import * as Constants from "../../Constants";
import {RELOAD_INTERVAL} from "../../Constants";
import {AllDevicesMap} from "../dashboard/AllDevicesMap";
import queryString from "query-string";

export function MultipleDeviceLocation({history, filter}) {
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();
    const [params, setParams] = useState(queryString.parse(history.location.search));

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Devices Location");
        subHeader.setBreadcrumbs([
            {
                "pathname": "/dashboard",
                "title": "Dashboard"
            },
            {
                "title": "Devices Location"
            }
        ]);

    }, [auth]);

    return (
        <>
            <div className="fw-bolder bg-white card gs-0 gy-4 p-4">
                <b>{params.seriesName}</b>
            </div>
            <br/>
            <div className="row">
                <div className="col-lg-12 col-xxl-12">
                    <AllDevicesMap params={params}/>
                </div>
            </div>
        </>
    );
}
