import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import ReactTooltip from "react-tooltip";

export function ActionsColumnFormatter(
    cellContent,
    row,
    rowIndex,
    {buttons}
) {
    return (
        <>
            <ReactTooltip place="top" type="dark" effect="solid" html={true}/>

            {buttons &&
            buttons.map(button => {
                let aClassName = "btn btn-icon btn-light btn-hover-" + button.color + " btn-sm mx-1";
                let spanClassName = "svg-icon svg-icon-md svg-icon-" + button.color;
                return (<a data-tip={button.name} key={button.name} className={aClassName}
                           onClick={() => button.method(row.id)}>
                    <span className={spanClassName}>
                        <SVG src={toAbsoluteUrl(button.icon)} title={button.name}/>
                    </span>
                </a>)
            })
            }
        </>
    );
}
