import React from 'react';
import Chart from 'react-apexcharts';

export function DashboardBarChartCard({title, data, onViewMore, unit = ""}) {
    const options = {
        series: data.data, chart: {
            type: 'bar', height: 350
        }, plotOptions: {
            bar: {
                borderRadius: 4, horizontal: false,
            }
        }, title: {
            text: title, align: 'left'
        }, dataLabels: {
            enabled: false
        }, xaxis: {
            categories: data.xAxixCategories,
        }, yaxis: {
            labels: {
                formatter: (value) => `${value} ${unit}`
            }
        }
    };

    return (<div className="dashboard-bar-chart-card">
        <Chart
            options={options}
            series={options.series}
            type="bar"
        />

        <div className="chart-footer" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px'}}>
            {onViewMore ?
                <a href="#" onClick={() => onViewMore(title)}>View More &gt;</a>
                : null
            }
        </div>

    </div>);
}
