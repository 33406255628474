import {toast} from "react-toastify";
import './ReactToastify.css';

export const TOAST_SUCCESS = 1;
export const TOAST_FAILURE = 2;
export const TOAST_WARNING = 3;

export const showToast = (data, type) => {
    if(type === TOAST_SUCCESS) {
        toast.success(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    } else if(type === TOAST_FAILURE) {
        toast.error(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }else if(type === TOAST_WARNING) {
        toast.warn(data, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }
};
