import React, {useState} from "react";
import {UsersCard} from "./UsersCard";
import {Route} from "react-router-dom";
import {UserEditDialog} from "./user-edit-dialog/UserEditDialog";
import {UserDeleteDialog} from "./user-delete-dialog/UserDeleteDialog";
import {UserPasswordDialog} from "./user-password-dialog/UserPasswordDialog";


export function UsersPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/users/new">
                {({history, match}) => (
                    <UserEditDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push("/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/users/:id/edit">
                {({history, match}) => (
                    <UserEditDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/users/:id/delete">
                {({history, match}) => (
                    <UserDeleteDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/users/:id/resetPassword">
                {({history, match}) => (
                    <UserPasswordDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <UsersCard history={history} reload={reload}/>
        </>
    );
}
