import React, {useState} from "react";
import {CommandsCard} from "./CommandsCard";
import {Route} from "react-router-dom";
import {NewCommandDialog} from "./NewCommandDialog";

export function CommandsPage({history, id}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/commands/new/:id">
                {({history, match}) => (
                    <NewCommandDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push(`/commands/${match && match.params.id}`);
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <CommandsCard id={id} history={history} reload={reloadData}/>
        </>
    );
}
