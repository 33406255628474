import React from "react";


export function RoleColumnFormatter(cellContent, row) {
    const getLabelCssClasses = (index) => {
        let color = 'info';
        switch (index) {
            case "VIEWER":
                color = "primary";
                break;
            case "ADMIN":
                color = "danger";
                break;
            case "SUPER_ADMIN":
                color = "warning";
                break;
            default:
                color = "warning";
                break;
        }

        return `label label-${
            color
        } label-inline`;
    };

    if (row.role != null) {
        return (
            <span key={row.role}>
                <span className={getLabelCssClasses(row.role)}>
                    {row.role}
                </span>
                &nbsp;
            </span>

        );
    }
}
