import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {isMobile} from "react-device-detect";
import {SUPER_ADMIN_DEVICE} from "../../crud";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {ActionsColumnFormatter} from "../../Common/column-formatters/ActionsColumnFormatter";
import {AdminDeviceFilter} from "./AdminDeviceFilter";

export function AdminDevicesCard({history, reload}) {

    const [tableFilterData, setTableFilterData] = useState(0);

    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);

    function filter(filterData) {
        setTableFilterData(filterData);
    }

    useEffect(() => {
        setTitleButtonGotoCallback(newButtonClick, "ADD NEW DEVICE");
    }, [auth]);

    function newButtonClick() {
        history.push("/admin/devices/new");
    }

    function openEditDialog(id) {
        history.push(`/admin/devices/${id}/edit`);
    }

    function openDeleteDialog(id) {
        history.push(`/admin/devices/${id}/delete`);
    }

    function moveDeviceDialog(id) {
        history.push(`/admin/devices/${id}/move`);
    }

    const columns = [
        {
            dataField: "imei",
            text: "IMEI",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: false
        },
        {
            dataField: "vehicleNo",
            text: "Vehicle No.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "batteryNo",
            text: "Battery No.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "orgName",
            text: "Organisation",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "createdDate",
            text: "Created Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                buttons:
                    [
                        {
                            name: "Edit",
                            color: "primary",
                            icon: "/media/svg/icons/Communication/Write.svg",
                            method: openEditDialog
                        },
                        {
                            name: "Delete",
                            color: "danger",
                            icon: "/media/svg/icons/Home/Trash.svg",
                            method: openDeleteDialog
                        },{
                        name: "Move Device to Other Org",
                        color: "info",
                        icon: "/media/svg/icons/Communication/Forward.svg",
                        method: moveDeviceDialog
                    },
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            hidden: isMobile
        },
    ];

    return (
        <Card>
            <CardBody>
                <AdminDeviceFilter filter={filter}/>
                <GenericTable reload={reload}
                              tableFilterData={tableFilterData}
                              defaultData={defaultList}
                              columns={columns}
                              url={SUPER_ADMIN_DEVICE}
                />
            </CardBody>
        </Card>
    );
}
