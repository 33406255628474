import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {defaultGroup} from "../../Common/generic/table/InitialStates";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {apiCreateGroup, apiGetGroupById, apiUpdateGroupById} from "../../crud";

export function GroupsEditDialog({id, show, onHide, reloadData}) {
    const [groupForEdit, setGroupForEdit] = useState(defaultGroup);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("New Group");

    const formik = useFormik({
        initialValues: groupForEdit,
        enableReinitialize: true,
        onSubmit(group) {
            setIsLoading(true);
            if (!id) {
                apiCreateGroup(group).then(() => {
                    setIsLoading(false);
                    onHide();
                    reloadData();
                    showToast("Group is Created!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            } else {
                apiUpdateGroupById(group).then(() => {
                    onHide();
                    reloadData();
                    showToast("Group is Updated!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        }
    });

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            apiGetGroupById(id).then(
                ({data}) => {
                    setGroupForEdit(data);
                    setTitle("Update Group");
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setGroupForEdit(defaultGroup);
            setTitle("New Group");
        }
    }, [id]);


    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm">

            {isLoading && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-12">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    id="name"
                                    placeholder="Enter Group Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                <Form.Text className="text-muted">Please enter the Group Name</Form.Text>
                            </Form.Group>

                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate">
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Save
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
