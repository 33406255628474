import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {isMobile} from "react-device-detect";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {ActionsColumnFormatter} from "../../Common/column-formatters/ActionsColumnFormatter";
import {APIKEYS, GROUPS} from "../../crud";

export function ApiKeyCard({history, reload}) {
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    useEffect(() => {
        setTitleButtonGotoCallback(newUserButtonClick, "CREATE NEW KEY");
    }, [auth]);

    function newUserButtonClick() {
        history.push("/apiKey/new");
    }

    function openDeleteDialog(id) {
        history.push(`/apiKey/${id}/delete`);
    }

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: true
        },
        {
            dataField: "apiKey",
            text: "API Key",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "createdDate",
            text: "Created Date",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "userId",
            text: "Created By",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "lastUsed",
            text: "Last Used",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                buttons:
                    [
                        {
                            name: "Delete",
                            color: "danger",
                            icon: "/media/svg/icons/Home/Trash.svg",
                            method: openDeleteDialog
                        },
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            hidden: isMobile
        },
    ];

    return (
        <Card>
            <CardBody>
                <GenericTable reload={reload}
                              tableFilterData={{}}
                              defaultData={defaultList}
                              columns={columns}
                              url={APIKEYS}
                />
            </CardBody>
        </Card>
    );
}
