import React, {useState} from "react";
import {AdminUsersCard} from "./AdminUsersCard";
import {Route} from "react-router-dom";
import {AdminUserEditDialog} from "./user-edit-dialog/AdminUserEditDialog";
import {AdminUserDeleteDialog} from "./user-delete-dialog/AdminUserDeleteDialog";
import {AdminUserPasswordDialog} from "./user-password-dialog/AdminUserPasswordDialog";


export function AdminUsersPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/admin/users/new">
                {({history, match}) => (
                    <AdminUserEditDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push("/admin/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/users/:id/edit">
                {({history, match}) => (
                    <AdminUserEditDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/admin/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/users/:id/delete">
                {({history, match}) => (
                    <AdminUserDeleteDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/admin/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/users/:id/resetPassword">
                {({history, match}) => (
                    <AdminUserPasswordDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/admin/users");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <AdminUsersCard history={history} reload={reload}/>
        </>
    );
}
