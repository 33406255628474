import React, {createContext, useReducer} from 'react';
import AuthReducer from './AuthReducer';

const initialState = {
    auth: {
        accessToken: "",
        fullName: "",
        isLoggedin: false,
        userId: "",
        profileImage: "",
        role: [],
        orgDeviceType: 0
    },
    titleButtonClickCallbackMethod: "",
    titleButtonName: ""
};


export const AuthContext = createContext(initialState);

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(AuthReducer, initialState);

    function doLogin(accessToken) {
        dispatch({
            type: 'LOGIN_COMPLETE',
            payload: accessToken
        });
    }

    function doLogout() {
        dispatch({
            type: 'LOGOUT_COMPLETE'
        });
    }

    function titleButtonClicked() {
        state.titleButtonClickCallbackMethod();
    }

    function setTitleButtonGotoCallback(callBackMethod, titleButtonName) {
        state.titleButtonClickCallbackMethod = callBackMethod;
        state.titleButtonName = titleButtonName;
        dispatch({
            type: 'SET_TITLE_BUTTON_NAME',
            payload: titleButtonName
        });
    }

    function saveMeData(data) {
        dispatch({
            type: 'ME_COMPLETE',
            payload: data
        });
    }

    function isAllowedToAccess (role) {
        return state.auth.role.some(r => (r.role === role));
    }

    function isSuperAdmin () {
        if(state.auth.role) {
            if (state.auth.role === "SUPER_ADMIN") {
                return true;
            } else {
                return false;
            }
        }
    }

    function isAdmin () {
        if(state.auth.role) {
            if (state.auth.role === "ADMIN") {
                return true;
            } else {
                return false;
            }
        }
    }

    function getOrgType () {
        return state.auth.orgDeviceType;
    }

    return (<AuthContext.Provider value={{
        auth: state.auth,
        doLogin,
        doLogout,
        saveMeData,
        titleButtonClicked,
        titleButtonClickCallbackMethod: state.titleButtonClickCallbackMethod,
        titleButtonName: state.titleButtonName,
        setTitleButtonGotoCallback,
        isAllowedToAccess,
        isSuperAdmin,
        isAdmin,
        getOrgType
    }}>
        {children}
    </AuthContext.Provider>);
};
