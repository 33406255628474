import React from "react";
import Chart from "react-apexcharts";

export function DonutChartCard({data, onDonutClick}) {

    // Enhanced options with a click event handler
    const optionsWithClick = {
        ...data.options,
        chart: {
            ...data.options.chart,
            events: {
                dataPointSelection: function(event, chartContext, config) {
                    // config contains information about the click event, like the series index and series name
                    const seriesName = data.options.labels[config.dataPointIndex];
                    // Call the passed function with the series name
                    onDonutClick(seriesName);
                }
            }
        }
    };

    return (
        <Chart
            options={optionsWithClick}
            series={data.series}
            labels={data.labels}
            type="donut"
            width="100%"
        />
    );
}
