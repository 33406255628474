import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {defaultOrg} from "../../Common/generic/table/InitialStates";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {apiCreateOrg, apiGetOrgById, apiUpdateOrgById} from "../../crud";
import {all_roles_user, org_device_types} from "../../Constants";
import AsyncSelect from "react-select/async";

export function OrgEditDialog({id, show, onHide, reloadData}) {
    const [orgForEdit, setOrgForEdit] = useState(defaultOrg);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("New Organisation");

    const formik = useFormik({
        initialValues: orgForEdit, enableReinitialize: true, onSubmit(org) {
            setIsLoading(true);
           let tempOrg = org;
            tempOrg.orgDeviceType = org.orgDeviceType.id;

            if (!id) {
                apiCreateOrg(tempOrg).then(() => {
                    setIsLoading(false);
                    onHide();
                    reloadData();
                    showToast("Organisation is Created!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            } else {
                apiUpdateOrgById(tempOrg).then(() => {
                    onHide();
                    reloadData();
                    showToast("Organisation is Updated!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        }
    });

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            apiGetOrgById(id).then(({ data }) => {
                // Find the device type object
                const deviceTypeObj = org_device_types.find(device => device.id === data.orgDeviceType);

                // If a matching type is found, use it, otherwise, handle the case where no match is found
                if (deviceTypeObj) {
                    data.orgDeviceType = { id: data.orgDeviceType, type: deviceTypeObj.type };
                } else {
                    // Handle the case when no matching type is found (e.g., set to a default value or handle as an error)
                    // For example, you might want to set it to a default value or handle it as needed
                    data.orgDeviceType = { id: data.orgDeviceType, type: "Unknown" };
                }

                setOrgForEdit(data);
                setTitle("Update Organisation");
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });

        } else {
            setOrgForEdit(defaultOrg);
            setTitle("New Organisation");
        }
    }, [id]);


    return (<Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm">

            {isLoading && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (<Form className="form form-label-right">
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-12">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    id="name"
                                    placeholder="Enter Organisation Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                <Form.Text className="text-muted">Please enter the Organisation Name</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-12">
                                <Form.Label>Organization Device Type</Form.Label>
                                <AsyncSelect
                                    isMulti={false}
                                    defaultOptions={org_device_types}
                                    value={formik.values.orgDeviceType}
                                    getOptionLabel={(option) => option.type}
                                    getOptionValue={(option) => option.id}
                                    onChange={option => formik.setFieldValue('orgDeviceType', option)}
                                />
                                <Form.Text className="text-muted">Please Select Organization Device Type, Data Display
                                    will be controlled using this setting.</Form.Text>
                            </Form.Group>

                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate">
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Save
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>)}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>);
}
