import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import {TripCard} from "./TripCard";
import {apiGetAllTrips} from "../../crud";
import {defaultList} from "../../Common/generic/table/InitialStates";

export function TripsPage({history}) {
    const [reload, setReload] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();
    const [dataList, setDataList] = useState(defaultList);

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Trips");
        subHeader.setBreadcrumbs([
            {
                "title": "Trips"
            }
        ]);

    }, [auth]);

    function reloadData() {
        setReload(reload + 1);
    }

    function goNext() {
        setCurrentIndex(currentIndex + sizePerPage)
    }

    function goPrevious() {
        if (currentIndex === 0) {
            return;
        }
        setCurrentIndex(currentIndex - sizePerPage)
    }

    useEffect(() => {

        apiGetAllTrips(currentIndex, sizePerPage).then(
            ({data}) => {
                setDataList(data)
            })
            .catch(() => {
            });

    }, [currentIndex]);

    return (
        <>
            <div className="row">
                {dataList.entities.map(function (trip, index) {
                    return <TripCard key={index}
                                     id={trip.id}
                                     history={history}
                                     trip={trip}
                    />
                })}
            </div>

            <div className="float-right">
                <a className="btn btn-icon btn-sm btn-outline-dark btn-hover-primary mr-2 my-1" onClick={goPrevious}>
                    <i className="ki ki-bold-arrow-back icon-xs"/>
                </a>

                <a className="btn btn-icon btn-sm btn-outline-dark btn-hover-primary mr-2 my-1" onClick={goNext}>
                    <i className="ki ki-bold-arrow-next icon-xs"/>
                </a>

                Page {(currentIndex / sizePerPage) + 1}
            </div>
        </>
    );
}
