import React, {useEffect, useState} from "react";
import {apiGetReportChartData} from "../crud";
import {REPORT_STATUS_REPORT} from "../Constants";
import {ReportsDevicesListCard} from "./ReportsDevicesListCard";
import {headerSortingClasses, sortCaret} from "../../../_metronic/_helpers";
import {HrefColumnFormatter} from "../Common/column-formatters/HrefColumnFormatter";
import {DateTimeColumnFormatter} from "../Common/column-formatters/DateTimeColumnFormatter";
import {DonutChartCard} from "./DonutChartCard";

export function StatusReportsPage({history, id}) {
    const [reload, setReload] = useState(0);
    const [data, setData] = useState({});
    const [donutClickData, setDonutClickData] = useState(0);
    const [selectionName, setSelectionName] = useState("Showing All Devices");

    const statusFormatter = (cell, row) => {
        // Calculate the difference between the current time and the time in the data
        const currentTime = new Date();
        const dataTime = new Date(row.time);
        const timeDifference = currentTime - dataTime; // This gives time difference in milliseconds

        // Check if dataTime is within 5 days (5 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        const isDisconnected = timeDifference > (6 * 24 * 60 * 60 * 1000);

        // Check the speed to determine if it's moving or idle
        const status = row.spd > 0 ? "Moving" : "Idle";

        // If it's disconnected, display "Disconnected", otherwise show the movement status
        return isDisconnected ? "🔴 Disconnected" : status;
    };

    const columns = [{
        dataField: "imei",
        text: "IMEI",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        hidden: false,
        formatter: HrefColumnFormatter,
        formatExtraData: {
            openChild: openChild, type: 1
        },
    }, {
        dataField: "odo",
        text: "ODOMETER",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell) => `${cell} kms`,
    }, {
        dataField: "spd",
        text: "SPEED",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell) => `${cell} km/h`,
    }, {
        dataField: "status", text: "STATUS", formatter: statusFormatter,
    },];

    function openChild(id) {
        history.push("/deviceDetails/" + id);
    }

    useEffect(() => {

        apiGetReportChartData(REPORT_STATUS_REPORT, (0)).then(({data}) => {
            setData(data)
        })
            .catch(() => {
            });

    }, [0]);

    function reloadData() {
        setReload(reload + 1);
    }

    const handleDonutClick = (seriesName) => {
        switch (seriesName) {
            case "Disconnected":
                setDonutClickData(3);
                setSelectionName("Showing Disconnected Devices")
                break;

            case "Idle":
                setDonutClickData(2);
                setSelectionName("Showing Idle Devices")
                break;

            case "Moving":
                setDonutClickData(1);
                setSelectionName("Showing Moving Devices")
                break;

            default:
                setDonutClickData(0);
                setSelectionName("Showing All Devices")
                break;
        }

        reloadData()
    };

    return (
        <div className="row d-flex" style={{height: '100%'}}>
            {/* Section 1 */}
            <div className="col-6 d-flex">
                <div id="kt_app_content_container1" className="app-container flex-grow-1 d-flex flex-column">
                    <div className="card flex-grow-1">
                        {Object.keys(data).length > 0 && <DonutChartCard data={data} onDonutClick={handleDonutClick} />}
                    </div>
                </div>
            </div>

            {/* Section 2 */}
            <div className="col-6 d-flex">
                <div id="kt_app_content_container2" className="app-container flex-grow-1 d-flex flex-column">
                    <div className="card flex-grow-1">
                        <ReportsDevicesListCard history={history} reload={reload} reportId={REPORT_STATUS_REPORT}
                                                selectedWeek={0} columns={columns} donutClickData={donutClickData} title={selectionName}/>
                    </div>
                </div>
            </div>
        </div>
    );

}
