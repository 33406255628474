import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AdminUsersFilter} from "./AdminUsersFilter";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {isMobile} from "react-device-detect";
import {StatusColumnFormatter} from "../../Common/column-formatters/StatusColumnFormatter";
import {RoleColumnFormatter} from "../../Common/column-formatters/RoleColumnFormatter";
import {SUPER_ADMIN_USER} from "../../crud";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {ProfilePicColumnFormatter} from "../../Common/column-formatters/ProfilePicColumnFormatter";
import {ActionsColumnFormatterCustomUser} from "../../Common/column-formatters/ActionsColumnFormatterCustomUser";

export function AdminUsersCard({history, reload}) {

    const [tableFilterData, setTableFilterData] = useState(0);

    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);


    useEffect(() => {
        setTitleButtonGotoCallback(newUserButtonClick, "NEW USER");
    }, [auth]);

    function newUserButtonClick() {
        history.push("/admin/users/new");
    }

    function filter(filterData) {
        setTableFilterData(filterData);
    }

    function openEditDialog(id) {
        history.push(`/admin/users/${id}/edit`);
    }

    function openDeleteDialog(id) {
        history.push(`/admin/users/${id}/delete`);
    }

    function openResetPasswordUserDialog(id) {
        history.push(`/admin/users/${id}/resetPassword`);
    }

    const columns = [
        {
            dataField: "profileImage",
            text: "Picture",
            sort: false,
            formatter: ProfilePicColumnFormatter,
            hidden: isMobile
        },
        {
            dataField: "userName",
            text: "User Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: false,
            hidden: isMobile
        },
        {
            dataField: "orgName",
            text: "Organisation",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "active",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            formatter: StatusColumnFormatter,
            headerSortingClasses,
        },
        {
            dataField: "role",
            text: "Role",
            sort: true,
            sortCaret: sortCaret,
            formatter: RoleColumnFormatter,
            headerSortingClasses,
            hidden: isMobile
        },
        {
            dataField: "loginCount",
            text: "Login Count",
            sort: true,
            hidden: isMobile
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatterCustomUser,
            formatExtraData: {
                buttons:
                    [
                        {
                            name: "Edit",
                            color: "primary",
                            icon: "/media/svg/icons/Communication/Write.svg",
                            method: openEditDialog
                        },
                        {
                            name: "Delete",
                            color: "danger",
                            icon: "/media/svg/icons/Home/Trash.svg",
                            method: openDeleteDialog
                        },
                        {
                            name: "Reset Password",
                            color: "info",
                            icon: "/media/svg/icons/General/Unlock.svg",
                            method: openResetPasswordUserDialog
                        }
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            hidden: isMobile
        },
    ];

    return (
        <Card>
            <CardBody>
                <AdminUsersFilter filter={filter}/>
                <GenericTable reload={reload}
                              tableFilterData={tableFilterData}
                              defaultData={defaultList}
                              columns={columns}
                              url={SUPER_ADMIN_USER}
                />
            </CardBody>
        </Card>
    );
}
