import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { showToast, TOAST_SUCCESS } from "../../Utils/toast/Toast";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { apiGetAllDevices, apiMulCommands, apiTageIMEIToGeoFence } from "../../crud";
import AsyncSelect from "react-select/async";
import { defaultGroup } from "../../Common/generic/table/InitialStates";

export function GeoFenceTagDialog({ id, show, onHide, reloadData }) {
    const [taggedDate, setCommandStr] = useState({ vehicles: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("Tag IMEI to GeoFence");
    const [allVehicles, setAllVehicles] = useState(defaultGroup);

    const formik = useFormik({
        initialValues: taggedDate,
        enableReinitialize: true,
        onSubmit: (values) => {
            const imeiList = values.vehicles.map(vehicle => vehicle.imei);
            setIsLoading(true);
            apiTageIMEIToGeoFence(imeiList, id)
                .then((data) => {
                    setIsLoading(false);
                    onHide();
                    reloadData();
                    showToast(data.data, TOAST_SUCCESS);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
    });

    useEffect(() => {
        if (show) {
            formik.resetForm();
        }
    }, [show]);

    useEffect(() => {
        apiGetAllDevices()
            .then(({ data }) => {
                setAllVehicles(data.entities);
            })
            .catch(() => {});
    }, []);

    const loadOptions = (inputValue, callback) => {
        const filteredOptions = allVehicles.filter(vehicle =>
            vehicle.imei.toLowerCase().includes(inputValue.toLowerCase()) ||
            vehicle.vehicleNo.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
    };

    return (
        <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-sm">
            {isLoading && <ModalProgressBar />}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right" onSubmit={formik.handleSubmit}>
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-12">
                                <Form.Label>Select Vehicles</Form.Label>
                                <AsyncSelect
                                    isMulti
                                    defaultOptions={allVehicles}
                                    loadOptions={loadOptions}
                                    value={formik.values.vehicles}
                                    getOptionLabel={(option) => option.imei + " (" + option.vehicleNo + ")"}
                                    getOptionValue={(option) => option.imei}
                                    onChange={(option) => formik.setFieldValue("vehicles", option)}
                                />
                                <Form.Text className="text-muted">Please select Vehicles</Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                                    Cancel
                                </button>
                                &nbsp;&nbsp;
                                <button type="submit" className="btn btn-primary btn-elevate">
                                    Tag IMEI
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
