import axios from "axios";
import {BASE_IP} from "./Constants";

export const LOGIN_URL = "api/signin";
export const ME_URL = "api/me";
export const SUPER_ADMIN_USER = "api/super/user";
export const SUPER_ADMIN_ORGS = "api/super/org";
export const SUPER_ADMIN_DEVICE = "api/super/device";
export const SUPER_ADMIN_RESET_PASSWORD = "api/super/resetPassword";

export const USER = "api/user";
export const GROUPS = "api/groups";
export const APIKEYS = "api/apikey";
export const DEVICE_DASHBOARD = "api/device/dashboard";
export const DEVICE_DASHBOARD_MAP = "api/device/dashboardMap";
export const DEVICE = "api/device";
export const RESET_PASSWORD = "api/resetPassword";
export const GRAFANA = "api/grafana";
export const REPORT = "api/report";
export const NEW_REPORT = "api/new-report";
export const TRIPS = "api/trips";
export const DASHBOARD = "api/dashboard";
export const COMMANDS = "api/commands";
export const MUL_COMMANDS = "api/mulcommands";
export const CHART_REPORTS = "api/chartReports";
export const SHARE_LOCATION = "api/shareLocation";
export const GEOFENCE = "api/geoFence";

export const SOFTWARE_VERSION = "1.1";

// -- LOGIN
export function apiLogin(username, password) {
    return axios.post(BASE_IP + LOGIN_URL, {username, password});
}

export function apiGetUserByToken() {
    return axios.get(BASE_IP + ME_URL);
}

export function apiGetAllListData(url, tableFilterData) {
    return axios.get(BASE_IP + url, {params: tableFilterData});
}

export function apiGetAllData(url, currentIndex, page, sizePerPage, status, text, filterDate) {
    return axios.get(BASE_IP + url + `?currentIndex=${currentIndex}&page=${page}&sizePerPage=${sizePerPage}&status=${status}&searchText=${text}&filterDate=${filterDate}`);
}

export function apiGetAllGroups() {
    return axios.get(BASE_IP + GROUPS + "/all");
}

export function apiGetAllDevices() {
    return axios.get(BASE_IP + DEVICE + "/all");
}

export function apiGetAllGroupsForOrg(orgId) {
    return axios.get(BASE_IP + GROUPS + "/forOrg/" + orgId);
}

export function apiGetAllTrips(currentIndex, sizePerPage) {
    return axios.get(BASE_IP + TRIPS + `?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}`);
}

export function apiCreateGroup(group) {
    return axios.post(BASE_IP + GROUPS, group);
}

export function apiUpdateGroupById(group) {
    return axios.post(BASE_IP + GROUPS + "/" + group.id, group);
}

export function apiGetGroupById(groupId) {
    return axios.get(BASE_IP + GROUPS + "/" + groupId);
}

export function apiDeleteGroup(id) {
    return axios.delete(BASE_IP + GROUPS + "/" + id);
}

export function apiDeleteAPIKey(id) {
    return axios.delete(BASE_IP + APIKEYS + "/" + id);
}

export function apiGenerateAPIKey() {
    return axios.post(BASE_IP + APIKEYS);
}


export function apiGetUserById(userId) {
    return axios.get(BASE_IP + USER + "/" + userId);
}

export function apiUpdateUserById(user) {
    return axios.post(BASE_IP + USER + "/" + user.id, user);
}

export function apiCreateUser(user) {
    return axios.post(BASE_IP + USER, user);
}

export function apiDeleteUser(id) {
    return axios.delete(BASE_IP + USER + "/" + id);
}

export function apiResetPassword(id, password) {
    return axios.post(BASE_IP + RESET_PASSWORD + "/" + id, password);
}

export function apiCreateDevice(device) {
    return axios.post(BASE_IP + DEVICE, device);
}

export function apiUpdateDeviceById(device) {
    return axios.post(BASE_IP + DEVICE + "/" + device.imei, device);
}

export function apiGetDeviceById(imei) {
    return axios.get(BASE_IP + DEVICE + "/" + imei);
}

export function apiGetAdminGrafanaList(params) {
    return axios.get(BASE_IP + GRAFANA + "/admin", {params: params});
}

export function apiGetBoxData(params) {
    return axios.get(BASE_IP + DASHBOARD + "/boxdata", {params: params});
}

export function apiGetUserGrafanaList(params) {
    return axios.get(BASE_IP + GRAFANA + "/user", {params: params});
}

export function apiGetDeviceDetailsGrafanaList(imei, params) {
    return axios.get(BASE_IP + GRAFANA + "/deviceDetails/" + imei, {params: params});
}

export function apiGetLatestLocationLog(imei) {
    return axios.get(BASE_IP + DEVICE + "/latestLocationLog/" + imei);
}

export function apiGetDeviceDetailsTableData(imei) {
    return axios.get(BASE_IP + DEVICE_DASHBOARD + "/tableData/" + imei);
}

export function apiGetTripsTableData(imei) {
    return axios.get(BASE_IP + TRIPS + "/" + imei);
}

export function apiGetTripByID(imei) {
    return axios.get(BASE_IP + TRIPS + "/trip/" + imei);
}

export function apiDownloadReport(type, imei, params) {
    console.log("Params: ", params);
    return axios.get(BASE_IP + REPORT + "/deviceDetails/" + type + "/" + imei, {params: params});
}

export function apiNewDownloadReport(type, params) {
    console.log("Params: ", params);
    return axios.get(BASE_IP + NEW_REPORT + "/" + type, {params: params});
}

export function apiCommands(command, imei) {
    return axios.post(BASE_IP + COMMANDS + "/" + imei, command);
}

export function apiMulCommands(command, imei) {
    return axios.post(BASE_IP + MUL_COMMANDS, command);
}

export function apiGetDeviceForRoutePlayback(imei, params) {
    return axios.get(BASE_IP + DEVICE + "/routePlayback/" + imei, {params: params});
}

export function apiGetDeviceForTripRoutePlayback(imei) {
    return axios.get(BASE_IP + TRIPS + "/tripRoutePlayback/" + imei);
}

// - SUPER ADMIN

export function apiCreateOrg(org) {
    return axios.post(BASE_IP + SUPER_ADMIN_ORGS, org);
}

export function apiUpdateOrgById(org) {
    return axios.post(BASE_IP + SUPER_ADMIN_ORGS + "/" + org.id, org);
}

export function apiGetOrgById(orgId) {
    return axios.get(BASE_IP + SUPER_ADMIN_ORGS + "/" + orgId);
}

export function apiSuperCreateDevice(device) {
    return axios.post(BASE_IP + SUPER_ADMIN_DEVICE, device);
}

export function apiSuperUpdateDeviceById(device) {
    return axios.post(BASE_IP + SUPER_ADMIN_DEVICE + "/" + device.imei, device);
}

export function apiSuperGetDeviceById(imei) {
    return axios.get(BASE_IP + SUPER_ADMIN_DEVICE + "/" + imei);
}

export function apiSuperDeleteDevice(imei) {
    return axios.delete(BASE_IP + SUPER_ADMIN_DEVICE + "/" + imei);
}

export function apiSuperMoveDevice(params) {
    return axios.get(BASE_IP + SUPER_ADMIN_DEVICE + "/moveDevice", {params});
}


export function apiGetUserByIdSuperAdmin(userId) {
    return axios.get(BASE_IP + SUPER_ADMIN_USER + "/" + userId);
}

export function apiUpdateUserByIdSuperAdmin(user) {
    return axios.post(BASE_IP + SUPER_ADMIN_USER + "/" + user.id, user);
}

export function apiCreateUserSuerpAdmin(user) {
    return axios.post(BASE_IP + SUPER_ADMIN_USER, user);
}

export function apiDeleteUserSuperAdmin(id) {
    return axios.delete(BASE_IP + SUPER_ADMIN_USER + "/" + id);
}

export function apiResetPasswordSuperAdmin(id, password) {
    return axios.post(BASE_IP + SUPER_ADMIN_RESET_PASSWORD + "/" + id, password);
}

export function apiDevicesForDashboardMap(parama) {
    return axios.get(BASE_IP + DEVICE_DASHBOARD_MAP, {params: parama});
}

export function apiGetTripRouteDetails(id) {
    return axios.get(BASE_IP + TRIPS + "/tripMap/" + id);
}

export function apiGetDeviceRouteDetails(id, params) {
    return axios.get(BASE_IP + DEVICE + "/map/" + id, {params: params});
}

// --------------

export function apiGetAllOrg() {
    return axios.get(BASE_IP + SUPER_ADMIN_ORGS + "/all");
}

//------- REPORTS
export function apiGetReportChartData(reportId, selectedWeek) {
    return axios.get(BASE_IP + CHART_REPORTS + `?reportId=${reportId}&selectedWeek=${selectedWeek}`);
}

export function apiEVDashboardCardData(params) {
    return axios.get(BASE_IP + DASHBOARD + "/evAllData", {params: params});
}

export function apiShareLocations(params) {
    return axios.post(BASE_IP + SHARE_LOCATION + "/", params);
}

export function apiGeoFenceCreate(params) {
    return axios.post(BASE_IP + GEOFENCE + "/", params);
}

export function apiDeleteGeoFence(id) {
    return axios.delete(BASE_IP + GEOFENCE + "/" + id);
}

export function getGeoFenceByID(id) {
    return axios.get(BASE_IP + GEOFENCE + "/" + id);
}

export function apiUpdateGeoFenceById(geofence) {
    return axios.post(BASE_IP + GEOFENCE + "/" + geofence.id, geofence);
}

export function apiTageIMEIToGeoFence(imeiList, id) {
    return axios.post(BASE_IP + GEOFENCE + "/tagImeis/" + id, imeiList);
}

export function getGeoFenceTags(id) {
    return axios.get(BASE_IP + GEOFENCE + "/tagImeis/" + id);
}

export function apiUntagFromGeoFence(geofenceId, imei) {
    return axios.delete(BASE_IP + GEOFENCE + "/tagImeis/" + geofenceId + "/" + imei);
}

export function apiGetCanData(group, imei) {
    return axios.get(BASE_IP + DEVICE + `/canData?group=${group}&imei=${imei}`);
}

export function apiDownloadCanData(group, imei, date) {
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    return axios.get(BASE_IP + DEVICE + `/canData/download`, {
        params: {
            group: group,
            imei: imei,
            date: formattedDate
        },
        responseType: 'blob'
    });
}

export function apiGetGeoFenceStatus(imei){
    return axios.get(BASE_IP + GEOFENCE + `Status/${imei}`);
}

export function apiGetAllGeoFenceStatuses(){
    return axios.get(BASE_IP + 'api/getAllGeoFenceStatuses/');
}