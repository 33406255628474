import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {apiCreateUser, apiGetAllGroups, apiGetUserById, apiUpdateUserById} from "../../../crud";
import {defaultGroups, defaultUser} from "../../../Common/generic/table/InitialStates";
import {showToast, TOAST_SUCCESS} from "../../../Utils/toast/Toast";
import {useFormik} from "formik";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";
import AsyncSelect from 'react-select/async';
import {all_roles_user} from "../../../Constants";
import {Switch} from "@material-ui/core";

export function UserEditDialog({id, show, onHide, reloadData}) {
    const [userForEdit, setUserForEdit] = useState(defaultUser);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("New User");
    const [allGroups, setAllGroups] = useState(defaultGroups);

    const formik = useFormik({
        initialValues: userForEdit,
        enableReinitialize: true,
        onSubmit(user) {

            const groups = [];
            for (const property in user.groups) {
                groups.push(user.groups[property].id)
            }

            user.groups = groups;
            user.role = user.role.id;

            setIsLoading(true);
            if (!id) {
                apiCreateUser(user).then(() => {
                    setIsLoading(false);
                    onHide();
                    reloadData();
                    showToast("User is Created!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            } else {
                apiUpdateUserById(user).then(() => {
                    onHide();
                    reloadData();
                    showToast("User is Updated!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        }
    });

    useEffect(() => {

        apiGetAllGroups().then(
            ({data}) => {
                setAllGroups(data.entities);
            })
            .catch(() => {
            });

    }, []);

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            apiGetUserById(id).then(
                ({data}) => {
                    data.groups = data.groupsDetails;

                    data.role = {
                        "id": data.role,
                        "role": data.role
                    };

                    setUserForEdit(data);
                    setTitle("Update User : " + data.userName);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setUserForEdit(defaultUser);
            setTitle("New User");
        }
    }, [id]);


    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg">

            {isLoading && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">
                        <Form.Row className="form-group row">

                            <Form.Group className="col-lg-4">
                                <Form.Label>Login User Name</Form.Label>
                                <Form.Control
                                    id="userName"
                                    placeholder="Enter Login UserName"
                                    onChange={formik.handleChange}
                                    value={formik.values.userName}
                                />
                                <Form.Text className="text-muted">Please enter the Login UserName</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-4">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                    id="name"
                                    placeholder="Enter Full Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                <Form.Text className="text-muted">Please enter the Name</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-4">
                                <Form.Label>Select Groups</Form.Label>
                                <AsyncSelect
                                    isMulti={true}
                                    defaultOptions={allGroups}
                                    value={formik.values.groups}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={option => formik.setFieldValue('groups', option)}
                                />
                                <Form.Text className="text-muted">Please select User's Group </Form.Text>
                            </Form.Group>

                        </Form.Row>

                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-4">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    id="phone"
                                    type="phone"
                                    placeholder="Enter Phone Number"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                />
                                <Form.Text className="text-muted">Please enter the Phone Number </Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-4">
                                <Form.Label>Select Roles</Form.Label>
                                <AsyncSelect
                                    isMulti={false}
                                    defaultOptions={all_roles_user}
                                    value={formik.values.role}
                                    getOptionLabel={(option) => option.role}
                                    getOptionValue={(option) => option.id}
                                    onChange={option => formik.setFieldValue('role', option)}
                                />
                                <Form.Text className="text-muted">Please select User's Role </Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-4">
                                <Form.Label>Active</Form.Label>
                                <Form.Group>
                                    <Switch
                                        id="active"
                                        name="active"
                                        type="checkbox"
                                        label="Check me out"
                                        onChange={formik.handleChange}
                                        value={formik.values.active}
                                        checked={formik.values.active}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                    <Form.Text className="text-muted">If a User is made as In-Active, He/She will not be
                                        able to login.</Form.Text>
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate">
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Save
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
