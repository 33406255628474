import {showToast, TOAST_FAILURE, TOAST_WARNING} from "../app/pages/Utils/toast/Toast";

export default function setupAxios(axios) {
    axios.interceptors.request.use(
        config => {
            const accessToken = localStorage.getItem("accessToken");
            console.log("API Call: " + config.method + " - " + config.url);
            if (accessToken) {
                let date = new Date()
                let offset = date.getTimezoneOffset();

                config.headers.Authorization = accessToken;
                config.headers.timeZoneOffset = offset;
            }

            return config;
        },
        err => Promise.reject(err)
    );

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response === undefined) {
            showToast("Not able to reach the server, Try again.", TOAST_FAILURE)
        } else if (error.response.status === 403) {
            showToast(error.response.data, TOAST_WARNING);
        } else if (error.response.status === 406) {
            showToast("You dont have permission to do this operation, Please Contact your Administrator", TOAST_WARNING);
        } else if (error.response.status !== 200) {
            showToast(error.response.data, TOAST_FAILURE);
        }

        return Promise.reject(error);
    });
}
