import React from "react";


export function MultiGroupLabelFormatter(cellContent, row) {

    if (row.groupsDetails != null) {
        return (
            Object.values(row.groupsDetails).map((value, index) => (
                <span key={index}>
                <span className={`label label-primary label-inline m-1`} style={{margin: 5}}>
                    {value.name}
                </span>
            </span>
            ))
        );
    }
}
