import React from "react";


export function ProfilePicColumnFormatter(cellContent, row) {
    return (
        <div className="symbol symbol-light-danger symbol-30 mr-3">
        <span className="symbol-label"
              style={{backgroundImage: 'url("' + row.profileImage + '")'}}/>
        </div>
    );
}
