import React, {useState} from "react";
import {GroupsCard} from "./GroupsCard";
import {Route} from "react-router-dom";
import {GroupsEditDialog} from "./GroupsEditDialog";
import {UserDeleteDialog} from "../users/user-delete-dialog/UserDeleteDialog";
import {GroupsDeleteDialog} from "./GroupsDeleteDialog";


export function GroupsPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/groups/new">
                {({history, match}) => (
                    <GroupsEditDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push("/groups");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/groups/:id/edit">
                {({history, match}) => (
                    <GroupsEditDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/groups");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/groups/:id/delete">
                {({history, match}) => (
                    <GroupsDeleteDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/groups");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <GroupsCard history={history} reload={reload}/>
        </>
    );
}
