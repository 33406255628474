import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {defaultOrg} from "../../Common/generic/table/InitialStates";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {all_reports} from "../../Constants";
import {apiDownloadReport} from "../../crud";

export function ReportDateRangeSelector({id, show, onHide, reloadData}) {
    const [isLoading, setIsLoading] = useState(false);

    const [selectedStartDate, setSelectedStartDate] = useState(new Date().setHours(0, 0, 0, 0));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date().setHours(23, 59, 59, 0));


    const formik = useFormik({
        initialValues: defaultOrg,
        enableReinitialize: true,
        onSubmit(report) {


            if(!report.report) {
                showToast("Please Select a Report Type", TOAST_FAILURE);
                return;
            }

            setIsLoading(true);
            showToast("Please wait, Generating the Report", TOAST_SUCCESS);

            let params = {
                startDate: new Date(selectedStartDate),
                endDate: new Date(selectedEndDate)
            };

            apiDownloadReport(report.report.id, id, params).then(
                ({data}) => {
                    setIsLoading(false);
                    let url = window.URL.createObjectURL(new Blob([data], {type: "text/csv"}));
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `REPORT-${report.id}-${id}-${selectedStartDate}-${selectedEndDate}.csv`;
                    a.click();
                    showToast("Report Downloaded", TOAST_SUCCESS);
                })
                .catch(() => {
                    setIsLoading(false)
                });
        }
    });

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm">

            {isLoading && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    Download Reports
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">

                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-12">
                                <Form.Label>Select Report</Form.Label>
                                <AsyncSelect
                                    isMulti={false}
                                    defaultOptions={all_reports}
                                    value={formik.values.report}
                                    getOptionLabel={(option) => option.report}
                                    getOptionValue={(option) => option.id}
                                    onChange={option => formik.setFieldValue('report', option)}
                                />
                                <Form.Text className="text-muted">Please select a Report </Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Form.Text className="text-muted">Please Select a Date range not greater than 7
                            Days </Form.Text>
                        <br/>
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-6">
                                <DatePicker className="form-control"
                                            selected={selectedStartDate}
                                            showTimeSelect
                                            startDate={selectedStartDate}
                                            endDate={selectedEndDate}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={(e) => {
                                                setSelectedStartDate(e);
                                            }}
                                />
                                <Form.Text className="text-muted">Please select the Start Date</Form.Text>
                            </Form.Group>
                            <Form.Group className="col-lg-6">
                                <DatePicker className="form-control"
                                            selected={selectedEndDate}
                                            showTimeSelect
                                            startDate={selectedStartDate}
                                            endDate={selectedEndDate}
                                            minDate={selectedStartDate}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={(e) => {
                                                setSelectedEndDate(e);
                                            }}
                                />
                                <Form.Text className="text-muted">Please select the End Date</Form.Text>
                            </Form.Group>

                        </Form.Row>


                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate">
                                    Close
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Download Report
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
