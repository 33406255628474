import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {defaultAdminDevice, defaultGroups} from "../../Common/generic/table/InitialStates";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {apiCreateDevice, apiGetAllGroups, apiGetDeviceById, apiUpdateDeviceById} from "../../crud";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export function DeviceEditDialog({id, show, onHide, reloadData}) {
    const [deviceForEdit, setDeviceForEdit] = useState(defaultAdminDevice);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("New Device");
    const [allGroups, setAllGroups] = useState(defaultGroups);

    const formik = useFormik({
        initialValues: deviceForEdit,
        enableReinitialize: true,
        onSubmit(device) {
            setIsLoading(true);
            if(device.groups) {
                device.group = device.groups.id;
            }

            if (!id) {
                apiCreateDevice(device).then(() => {
                    setIsLoading(false);
                    onHide();
                    reloadData();
                    showToast("Device is Created!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            } else {
                apiUpdateDeviceById(device).then(() => {
                    onHide();
                    reloadData();
                    showToast("Device is Updated!", TOAST_SUCCESS);
                }).catch(() => {
                    setIsLoading(false);
                });
            }
        }
    });

    useEffect(() => {

        apiGetAllGroups().then(
            ({data}) => {
                setAllGroups(data.entities);
            })
            .catch(() => {
            });

    }, []);

    useEffect(() => {
        if (id != null) {
            setIsLoading(true);
            apiGetDeviceById(id).then(
                ({data}) => {
                    data.groups = data.groupDetails;

                    setDeviceForEdit(data);
                    setTitle("Update Device");
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setDeviceForEdit(defaultAdminDevice);
            setTitle("New Device");
        }
    }, [id]);


    return (
        <Modal
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm">

            {isLoading && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-6">
                                <Form.Label>IMEI</Form.Label>
                                <Form.Control
                                    id="imei"
                                    placeholder="Enter IMEI"
                                    onChange={formik.handleChange}
                                    value={formik.values.imei}
                                />
                                <Form.Text className="text-muted">Please enter the IMEI</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-6">
                                <Form.Label>Vehicle Number</Form.Label>
                                <Form.Control
                                    id="vehicleNo"
                                    placeholder="Enter Vehicle Number"
                                    onChange={formik.handleChange}
                                    value={formik.values.vehicleNo}
                                />
                                <Form.Text className="text-muted">Please enter the Vehicle Number</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-6">
                                <Form.Label>Battery Number</Form.Label>
                                <Form.Control
                                    id="batteryNo"
                                    placeholder="Enter Battery Number"
                                    onChange={formik.handleChange}
                                    value={formik.values.batteryNo}
                                />
                                <Form.Text className="text-muted">Please enter the Battery Number</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-6">
                                <Form.Label>Select Group</Form.Label>
                                <AsyncSelect
                                    isMulti={false}
                                    defaultOptions={allGroups}
                                    value={formik.values.groups}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={option => formik.setFieldValue('groups', option)}
                                />
                                <Form.Text className="text-muted">Please select Device's Group </Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate">
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Save
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
