export const all_roles = [
    {id: "SUPER_ADMIN", role: "SUPER_ADMIN"},
    {id: "ADMIN", role: "ADMIN"},
    {id: "VIEWER", role: "VIEWER"},
];

export const all_roles_user = [
    {id: "ADMIN", role: "ADMIN"},
    {id: "VIEWER", role: "VIEWER"},
];

export const org_device_types = [
    {id: 1, type: "ICE"},
    {id: 2, type: "EV"},
    {id: 3, type: "BOTH"},
];

export const all_reports = [
    {id: "type-1", report: "Battery report"},
    {id: "type-2", report: "Fleet report"},
    {id: "type-3", report: "Detailed report - 1"},
    {id: "type-4", report: "Detailed report - 2"},
    {id: "type-5", report: "Trip Summary"},
];

export let BASE_IP = "";
export let COMPANY_NAME = "";
export let LOGO_DARK = "";
export let LOGO_LIGHT = "";
export let MAIN_LOGO = "";

export let RELOAD_INTERVAL = 70;

if (process.env.REACT_APP_ENV === "prod") {
    BASE_IP = "https://ev-backend.trakmatesolutions.com/";
    COMPANY_NAME = "TrakMate"
    LOGO_DARK = "logo-dark-trakmate.png"
    LOGO_LIGHT = "logo-light-trakmate.png"
    MAIN_LOGO = "logo-trakmate.png"
} else if (process.env.REACT_APP_ENV === "dev") {
    BASE_IP = "http://localhost:8001/";
    COMPANY_NAME = "LocalHost"
    LOGO_DARK = 'logo-dark-localhost.png'
    LOGO_LIGHT = 'logo-light-localhost.png'
    MAIN_LOGO = "logo-localhost.png"
} else if (process.env.REACT_APP_ENV === "aeris") {
    BASE_IP = "https://ev-backend.aerisiot.co.in/";
    COMPANY_NAME = "Aeris"
    LOGO_DARK = 'logo-dark-aeris.png'
    LOGO_LIGHT = 'logo-light-aeris.png'
    MAIN_LOGO = "logo-aeris.png"
}


export let GOOGLE_MAPS_KEY = "AIzaSyDverWqvWFmiPmrrs3GfwV00Mgz4LCfNFs";

export let REPORT_MOVEMENT_REPORT = 1;
export let REPORT_OVERSPEED_REPORT = 2;
export let REPORT_STATUS_REPORT = 3;
