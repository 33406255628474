import React, {useState} from "react";
import {DevicesCard} from "./DevicesCard";
import {Route} from "react-router-dom";
import {DeviceEditDialog} from "./DeviceEditDialog";


export function DevicesPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/devices/new">
                {({history, match}) => (
                    <DeviceEditDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push("/devices");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/devices/:id/edit">
                {({history, match}) => (
                    <DeviceEditDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/devices");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <DevicesCard history={history} reload={reload}/>
        </>
    );
}
