import React, {useContext, useState} from "react";
import {Field, Formik} from "formik";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../_metronic/_partials/controls";
import {apiGetUserByToken, apiLogin} from "../../crud";
import {AuthContext} from './context/AuthState';

function Login() {
    const [loading, setLoading] = useState(false);
    const {doLogin, doLogout, saveMeData} = useContext(AuthContext);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (meta, fieldname) => {
        let result = "form-control form-control-solid h-auto py-5 px-6 ";
        if (meta.touched && meta.error) {
            result += " is-invalid";
        }

        if (meta.touched && !meta.error) {
            result += " is-valid";
        }

        return result;
    };

    return (
        <div className="login-form login-signin">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE"/>
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter your username and password
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}

            <Formik
                initialValues={{
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.userName) {
                        errors.userName = "Username is required.";
                    }

                    if (!values.password) {
                        errors.password = "Required field";
                    }

                    return errors;
                }}
                onSubmit={(values, {setStatus, setSubmitting}) => {
                    enableLoading();
                    setTimeout(() => {
                        apiLogin(values.userName, values.password)
                            .then(
                                ({data: {accessToken}}) => {
                                    disableLoading();
                                    doLogin(accessToken);

                                    apiGetUserByToken()
                                        .then(
                                            ({data}) => {
                                                saveMeData(data);
                                            })
                                        .catch(() => {
                                            //console.log("Me Called Failed, Cleaning Auth State!");
                                            doLogout();
                                        });
                                })
                            .catch(() => {
                                disableLoading();
                                setSubmitting(false);
                                setStatus("The login detail is incorrect");
                            });
                    }, 1000);
                }}
            >
                {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form
                        className="form"
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        {status ? (
                            <div
                                role="alert"
                                className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                            >
                                <div className="alert-text font-weight-bold">{status}</div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className="form-group">
                            <Field
                                name="userName"
                                component={Input}
                                placeholder="User Name"
                                label="userName"
                            >
                                {({field, form, meta}) => (
                                    <div>
                                        <input
                                            type="userName"
                                            {...field}
                                            className={`${getInputClasses(meta)}`}
                                            placeholder="Enter User Name"
                                        />
                                        {meta.touched && meta.error && (
                                            <div className="error invalid-feedback">{meta.error}</div>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        <div className="form-group">
                            <Field
                                name="password"
                                component={Input}
                                placeholder="Password"
                                label="Password"
                            >
                                {({field, form, meta}) => (
                                    <div>
                                        <input
                                            type="password"
                                            {...field}
                                            className={`${getInputClasses(meta)}`}
                                            placeholder="Enter Password"
                                        />
                                        {meta.touched && meta.error && (
                                            <div className="error invalid-feedback">{meta.error}</div>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>

                        {/* begin::Actions */}
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                            >
                                <span>Sign In</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}
                    </form>
                )}
            </Formik>
            {/*end::Form*/}
        </div>
    );
}

export default Login;
