import React from "react";


export function HrefColumnFormatter(cellContent,
                                    row,
                                    rowIndex,
                                    {openChild, type}) {
    switch(type) {
        case 1:
            return (
                <span style={{color: '#5fa4ff', cursor: "pointer"}} onClick={() => openChild(row.imei)}>{row.imei}</span>
            );

        case 2:
            return (
                <span style={{color: '#5fa4ff', cursor: "pointer"}} onClick={() => openChild(row.imei)}>{row.location}</span>
            );

        case 3:
            return (
                <span style={{color: '#5fa4ff', cursor: "pointer"}} onClick={() => openChild(row.imei)}>{row.address}</span>
            );

        default:
            return (
                <span style={{color: '#5fa4ff', cursor: "pointer"}} onClick={() => openChild(row.path)}>{row.name}</span>
            );
    }
}
