import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {isMobile} from "react-device-detect";
import {SUPER_ADMIN_ORGS, SUPER_ADMIN_USER} from "../../crud";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {ActionsColumnFormatter} from "../../Common/column-formatters/ActionsColumnFormatter";
import {OrgTypeColumnFormatter} from "../../Common/column-formatters/OrgTypeColumnFormatter";

export function OrgsCard({history, reload}) {

    const [tableFilterData, setTableFilterData] = useState(0);

    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);


    useEffect(() => {
        setTitleButtonGotoCallback(newUserButtonClick, "ADD NEW ORGANISATION");
    }, [auth]);

    function newUserButtonClick() {
        history.push("/admin/orgs/new");
    }

    function openEditDialog(id) {
        history.push(`/admin/orgs/${id}/edit`);
    }

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: false
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "orgDeviceType",
            text: "Org Device Type",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: OrgTypeColumnFormatter
        },
        {
            dataField: "createdDate",
            text: "Created Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                buttons:
                    [
                        {
                            name: "Edit",
                            color: "primary",
                            icon: "/media/svg/icons/Communication/Write.svg",
                            method: openEditDialog
                        }
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            hidden: isMobile
        },
    ];

    return (
        <Card>
            <CardBody>
                <GenericTable reload={reload}
                              tableFilterData={tableFilterData}
                              defaultData={defaultList}
                              columns={columns}
                              url={SUPER_ADMIN_ORGS}
                />
            </CardBody>
        </Card>
    );
}
