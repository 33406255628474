import React, {useState} from "react";
import {CommandsCard} from "./CommandsCard";
import {Route} from "react-router-dom";
import {NewCommandDialog} from "./NewCommandDialog";
import {MulCommandsCard} from "./MulCommandsCard";
import {MulNewCommandDialog} from "./MulNewCommandDialog";

export function MulCommandsPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/mulCommands/new/">
                {({history, match}) => (
                    <MulNewCommandDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push(`/mulCommands`);
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <MulCommandsCard id={'ALL'} history={history} reload={reloadData}/>
        </>
    );
}
