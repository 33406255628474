import React, {useContext, useEffect, useState} from "react";
import {SuperAdminDashboardPage} from "../SuperAdmin/dashboard/SuperAdminDashboardPage";
import {AuthContext} from "../Common/auth/context/AuthState";
import {useSubheader} from "../../../_metronic/layout";
import {NewDashboardPage} from "../User/dashboard/NewDashboardPage";

export function DashboardPage({history}) {
    const [reload, setReload] = useState(0);
    const {isSuperAdmin} = useContext(AuthContext);
    const {getOrgType} = useContext(AuthContext);
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Dashboard");
        subHeader.setBreadcrumbs([
            {
                "title": "Dashboard"
            }
        ]);

    }, [auth]);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            {isSuperAdmin() && <SuperAdminDashboardPage history={history} reload={reload}/>}

            {!isSuperAdmin() && getOrgType() === 1 && <NewDashboardPage history={history} reload={reload} orgType={"ICE"}/>}

            {!isSuperAdmin() && getOrgType() === 2 && <NewDashboardPage history={history} reload={reload} orgType={"EV"}/>}

            {!isSuperAdmin() && getOrgType() === 3 && <NewDashboardPage history={history} reload={reload} orgType={"EV"}/>}

        </>
    );
}
