import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { showToast, TOAST_FAILURE, TOAST_SUCCESS } from "../../Utils/toast/Toast";
import { defaultOrg } from "../../Common/generic/table/InitialStates";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import DatePicker from "react-datepicker";
import { apiNewDownloadReport, apiGetAllGroups, apiGetAllDevices } from "../../crud";
import AsyncSelect from "react-select/async";
import { defaultGroups } from "../../Common/generic/table/InitialStates";
import * as Yup from "yup";

export function DownloadReportDateRangeSelector({ id, imei, show, onHide, reloadData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    });
    const [selectedEndDate, setSelectedEndDate] = useState(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 0);
        return date;
    });
    const [allGroups, setAllGroups] = useState(defaultGroups);
    const [allVehicles, setAllVehicles] = useState([]);

    const validationSchema = Yup.object().shape({
        groups: Yup.array(),
        vehicles: Yup.array(),
    }).test('groups-or-vehicles', 'Please select at least one group or vehicle', function (values) {
        const { groups, vehicles } = values;
        if ((groups && groups.length > 0) && (vehicles && vehicles.length > 0)) {
            return this.createError({ message: "You can't select both groups and vehicles" });
        }
        if ((!groups || groups.length === 0) && (!vehicles || vehicles.length === 0)) {
            return this.createError({ message: "Please select at least one group or vehicle" });
        }
        return true;
    });

    const formik = useFormik({
        initialValues: { ...defaultOrg, groups: [], vehicles: [] },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit() {
            if (formik.values.groups.length === 0 && formik.values.vehicles.length === 0) {
                showToast("Please select at least one group or vehicle", TOAST_FAILURE);
                return;
            }

            setIsLoading(true);
            showToast("Please wait, Generating the Report", TOAST_SUCCESS);

            let params = {
                startDate: new Date(selectedStartDate),
                endDate: new Date(selectedEndDate),
                groups: formik.values.groups.map(group => group.id).join(','),
                vehicles: formik.values.vehicles.map(vehicle => vehicle.imei).join(','),
            };

            apiNewDownloadReport(id, params)
                .then(({ data }) => {
                    setIsLoading(false);
                    let url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `REPORT-${id}-${selectedStartDate}-${selectedEndDate}.csv`;
                    a.click();
                    showToast("Report Downloaded", TOAST_SUCCESS);
                })
                .catch(() => {
                    setIsLoading(false);
                    showToast("Failed to Download Report", TOAST_FAILURE);
                });
        },
    });

    useEffect(() => {
        apiGetAllGroups()
            .then(({ data }) => {
                setAllGroups(data.entities);
            })
            .catch(() => {});

        apiGetAllDevices()
            .then(({ data }) => {
                setAllVehicles(data.entities);
                // If imei is provided, find and set the corresponding vehicle
                if (imei) {
                    const selectedVehicle = data.entities.find(vehicle => vehicle.imei === imei);
                    if (selectedVehicle) {
                        formik.setFieldValue("vehicles", [selectedVehicle]);
                    }
                }
            })
            .catch(() => {});
    }, [imei]);

    const loadVehicleOptions = (inputValue, callback) => {
        const filteredOptions = allVehicles.filter(vehicle =>
            vehicle.imei.toLowerCase().includes(inputValue.toLowerCase()) ||
            vehicle.vehicleNo.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
    };

    return (
        <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-sm">
            {isLoading && <ModalProgressBar />}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">Download Reports</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">
                        <Form.Text className="text-muted">
                            Please Select a Date range not greater than 7 Days{" "}
                        </Form.Text>
                        <br />
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-6">
                                <DatePicker
                                    className="form-control"
                                    selected={selectedStartDate}
                                    showTimeSelect
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    onChange={(e) => {
                                        setSelectedStartDate(e);
                                    }}
                                />
                                <Form.Text className="text-muted">Please select the Start Date</Form.Text>
                            </Form.Group>
                            <Form.Group className="col-lg-6">
                                <DatePicker
                                    className="form-control"
                                    selected={selectedEndDate}
                                    showTimeSelect
                                    startDate={selectedStartDate}
                                    endDate={selectedEndDate}
                                    minDate={selectedStartDate}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    onChange={(e) => {
                                        setSelectedEndDate(e);
                                    }}
                                />
                                <Form.Text className="text-muted">Please select the End Date</Form.Text>
                            </Form.Group>
                            <Form.Group className="col-lg-12">
                                <Form.Label>Select Groups</Form.Label>
                                <AsyncSelect
                                    isMulti={true}
                                    defaultOptions={allGroups}
                                    value={formik.values.groups}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => {
                                        formik.setFieldValue("groups", option || []);
                                        formik.setFieldValue("vehicles", []);
                                    }}
                                    isDisabled={formik.values.vehicles.length > 0}
                                />
                                <Form.Text className="text-muted">Please select Groups</Form.Text>
                            </Form.Group>
                            <Form.Group className="col-lg-12">
                                <Form.Label>Select Vehicles</Form.Label>
                                <AsyncSelect
                                    isMulti={true}
                                    defaultOptions={allVehicles}
                                    loadOptions={loadVehicleOptions}
                                    value={formik.values.vehicles}
                                    getOptionLabel={(option) => option.imei + " (" + option.vehicleNo + ")"}
                                    getOptionValue={(option) => option.imei}
                                    onChange={(option) => {
                                        formik.setFieldValue("vehicles", option || []);
                                        formik.setFieldValue("groups", []);
                                    }}
                                    isDisabled={formik.values.groups.length > 0}
                                />
                                <Form.Text className="text-muted">Please select Vehicles</Form.Text>
                            </Form.Group>
                            {formik.errors.groups && formik.touched.groups && (
                                <Form.Text className="text-danger">{formik.errors.groups}</Form.Text>
                            )}
                            {formik.errors.vehicles && formik.touched.vehicles && (
                                <Form.Text className="text-danger">{formik.errors.vehicles}</Form.Text>
                            )}
                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                                    Close
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate"
                                >
                                    Download Report
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}