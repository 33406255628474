export const defaultList = {
    totalCount: 0,
    entities: []
};

export const defaultOrg = {
    id: "",
    name: "",
    orgDeviceType: 2,
    createdDate: "",
};

export const defaultReport = {
    report: "",
    startDate: "",
    createdDate: "",
};
export const defaultGroups = [{
    id: "",
    name: "",
}];

export const defaultGroup = {
    id: "",
    name: "",
    orgId: "",
};

export const defaultAdminDevice = {
    id: "",
    imei: "",
    vehicleNo: "",
    batteryNo: "",
    org: "",
};

export const sizePerPageList = [
    {text: "10", value: 10},
    {text: "50", value: 50},
    {text: "100", value: 100}
];

export const defaultUser = {
    userName: "",
    name: "",
    phone: "",
    password: "",
    active: true,
    role: "",
    org: "",
};

export const defaultLocationLog = {
    imei: "NA",
    lat: "0",
    lng: "0"
};

export const defaultGeoFence = {
    id: "",
    name: "",
    location: "",
    range: 0,
    detectMoveIn: true,
    detectMoveOut: true,
    orgId: "",
    latitude: 0,
    longitude: 0,
};

export const defaultSelectSearch = [];

export const UserStatusCssClasses = ["danger", "success"];
export const UserStatusTitles = ["Inactive", "Active"];
