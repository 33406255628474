import React from "react";
import {UserStatusCssClasses, UserStatusTitles} from "../generic/table/InitialStates";


export function StatusColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-${
            UserStatusCssClasses[row.active? 1 : 0]
        } label-inline`;
    };
    return (
        <span className={getLabelCssClasses()}>
      {UserStatusTitles[row.active? 1 : 0]}
    </span>
    );
}
