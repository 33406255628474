import React, {useContext, useState} from "react";
import * as Utils from "../../Utils/Utils";
import {TripMap} from "./TripMap";
import {Button} from "@material-ui/core";
import {apiDownloadReport} from "../../crud";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {AuthContext} from "../../Common/auth/context/AuthState";

export function TripCardWithMap({history, id, trip}) {
    const {getOrgType} = useContext(AuthContext);

    function routePlayback() {
        history.push(`/tripRoutePlayback/${id}`);
    }

    function downloadReport() {

        showToast("Please wait, Generating the Report", TOAST_SUCCESS);

        let params = {
            date: ""
        };

        apiDownloadReport("trip", id, params).then(
            ({data}) => {
                let url = window.URL.createObjectURL(new Blob([data], {type: "text/csv"}));
                let a = document.createElement('a');
                a.href = url;
                a.download = `REPORT-TRIP-${id}.csv`;
                a.click();
                showToast("Report Downloaded", TOAST_SUCCESS);
            })
            .catch(() => {
            });
    }

    return (
        <div className="col-lg-12 col-xxl-12">
            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                <div className="mr-3">
                                    <a href="#"
                                       className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{trip.imei}</a>
                                </div>
                                {trip.tripStatus === 0 &&
                                <div className="my-lg-0 my-1">
                                    <span className="label label-danger label-inline m-1">IN PROGRESS</span>
                                </div>
                                }

                                {trip.tripStatus === 1 &&
                                <div className="my-lg-0 my-1">
                                    <span className="label label-primary label-inline m-1">COMPLETED</span>
                                </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="separator separator-solid my-7"/>

                    <div className="card-spacer bg-white card-rounded flex-grow-1">
                        <div className="row">
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Start Time</div>
                                <div
                                    className="font-size-h4 font-weight-bolder">{Utils.getFormattedTime(trip.tripStartTime, 'h:mm a', 'NA')}</div>
                                <div
                                    className="font-size-sm text-muted font-weight-bold">{Utils.getFormattedTime(trip.tripStartTime, 'DD MMM YYYY', '')}</div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">End Time</div>
                                <div
                                    className="font-size-h4 font-weight-bolder">{Utils.getFormattedTime(trip.tripEndTime, 'h:mm a', 'NA')}</div>
                                <div
                                    className="font-size-sm text-muted font-weight-bold">{Utils.getFormattedTime(trip.tripEndTime, 'DD MMM YYYY', '')}</div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Total Time</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.totalTime} <span
                                    className="font-size-sm text-muted font-weight-bold">mins</span></div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Total Distance</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.totalDistance} <span
                                    className="font-size-sm text-muted font-weight-bold">kms</span></div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Start ODO Reading</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.startOdoReading}</div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Trip Max Speed</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.maxSpeed} <span
                                    className="font-size-sm text-muted font-weight-bold">km/h</span></div>
                            </div>

                        </div>

                        {getOrgType() !== 1 &&
                        <div className="row">
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Trip Start SOC</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.startSOCReading} <span
                                    className="font-size-sm text-muted font-weight-bold">%</span></div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Trip End SOC</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.endSOCReading} <span
                                    className="font-size-sm text-muted font-weight-bold">%</span></div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">End ODO Reading</div>
                                <div className="font-size-h4 font-weight-bolder">{trip.endOdoReading}</div>
                            </div>
                        </div>
                        }
                        <hr/>

                        <div className="row">
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">Start Address</div>
                                <div className="font-size-sm font-weight-bold">{trip.startAddress}</div>
                            </div>
                            <div className="col py-3">
                                <div className="font-size-sm text-muted font-weight-bold">End Address</div>
                                <div className="font-size-sm font-weight-bold">{trip.endAddress}</div>
                            </div>

                            <div className="col py-3">
                                <Button onClick={() => downloadReport()}
                                        className="btn btn-sm btn-light-primary font-weight-bold ml-2 mr-2">
                                    <span className="flaticon2-download">&nbsp;&nbsp;Download Trip</span>
                                </Button>

                                <Button onClick={() => routePlayback()}
                                        className="btn btn-sm btn-light-primary font-weight-bold ml-2 mr-2">
                                    <span className="flaticon2-map">&nbsp;&nbsp;Route Playback</span>
                                </Button>
                            </div>

                        </div>

                        <hr/>
                        <small>** Map will be available only for completed trips.</small>

                        <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                                <TripMap id={trip.id}
                                         history={history}
                                         trip={trip}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
