import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {useFormik} from "formik";
import {apiShareLocations} from "../../crud";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";

export function ShareLocationDialog({id, show, onHide, reloadData}) {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {phone: '', imei: ''}, enableReinitialize: true, onSubmit: (shareDetails) => {
            moveDevice(shareDetails);
        }
    });

    const moveDevice = (shareDetails) => {
        setIsLoading(true);

        // Constructing the parameters for the API call
        let params = {
            phone: shareDetails.phone, imei: id,
        };

        apiShareLocations(params)
            .then(() => {
                showToast("Location Shared", TOAST_SUCCESS);
                reloadData();
            })
            .catch(() => {
            })
            .finally(() => {
                setIsLoading(false);
                onHide();
            });
    };

    return (<Modal size="sm" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-sm">
        {isLoading && <ModalProgressBar/>}
        <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
                Share Location
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay">
            {!isLoading && (<Form className="form form-label-right" onSubmit={formik.handleSubmit}>
                <Form.Row className="form-group row">
                    <Form.Group className="col-lg-12">
                        <Form.Label>Phone Number (add +91)</Form.Label>
                        <Form.Control
                            id="phone"
                            type="phone"
                            placeholder="Enter Phone Number"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                        <Form.Text className="text-muted">Please enter the Phone Number to share the Location via
                            SMS</Form.Text>
                    </Form.Group>

                </Form.Row>

                <Modal.Footer>
                    <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                        Cancel
                    </button>
                    <> </>
                    <button type="submit" className="btn btn-primary btn-elevate">
                        Share Location
                    </button>
                </Modal.Footer>
            </Form>)}
            {isLoading && <span>Please Wait, The Device is moving to the destination Org</span>}
        </Modal.Body>
    </Modal>);
}
