import React, {useContext, useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {AuthPage} from "./pages/Common/auth/AuthPage";
import {AuthContext} from "./pages/Common/auth/context/AuthState";
import {apiGetUserByToken} from "./pages/crud";
import ErrorsPage from "./pages/Common/errors/ErrorsPage";
import Logout from "./pages/Common/auth/Logout";

export function Routes() {
    const {auth, doLogout, saveMeData} = useContext(AuthContext);
    let storedAccessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        if (auth.accessToken === null || auth.accessToken.length <= 10) {
            if (storedAccessToken !== null && storedAccessToken.length >= 10) {
                //console.log("Token Already Present, Calling me API");
                apiGetUserByToken()
                    .then(
                        ({data}) => {
                            saveMeData(data);
                        })
                    .catch(() => {
                        //console.log("Me Call Failed, Cleaning Auth State!");
                        doLogout();
                    });
            }
        }
    }, [auth]);

    return (
        <Switch>
            {!(storedAccessToken !== null && storedAccessToken.length >= 10) ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage/>
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>


            {!(storedAccessToken !== null && storedAccessToken.length >= 10) ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
