import Moment from 'moment';

export function isEmpty(strValue) {
    return !strValue || strValue.trim() === "" || (strValue.trim()).length === 0;
}

export function getFormattedTime(date, format, defaultData) {
    if(!date)
        return defaultData;

    return  Moment(date).format(format)
}
