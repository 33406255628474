import React, {useEffect, useState} from "react";
import {FeatureGroup, MapContainer, Marker, Polyline, Popup, useMap} from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import * as Constants from "../../Constants";
import {apiGetDeviceRouteDetails, apiGetTripRouteDetails} from "../../crud";
import {defaultList} from "../../Common/generic/table/InitialStates";
import L, {LatLngBounds} from "leaflet";
import * as Utils from "../../Utils/Utils";

export function DeviceDetailsMap({history, id, selectedDate}) {
    const [reload, setReload] = useState(0);
    const [data, setData] = useState(defaultList);
    const [pos, setPos] = useState([]);
    const [map, setMap] = useState(null);

    function reloadData() {
        setReload(reload + 1);
    }

    const Recenter = ({lat, lng}) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);

            const bounds = new LatLngBounds(pos);
            map.fitBounds(bounds);
        }, [lat, lng]);
        return null;
    };

    const redDot = new L.Icon({
        iconUrl: "/media/png/red-dot.png",
        iconSize: [10, 10],
        iconAnchor: [5, 5],
        popupAnchor: [2, -40]
    });

    const startDot = new L.Icon({
        iconUrl: "/media/png/start.png",
        iconSize: [50, 50],
        iconAnchor: [25, 40],
        popupAnchor: [2, -40]
    });

    const endDot = new L.Icon({
        iconUrl: "/media/png/end.png",
        iconSize: [50, 50],
        iconAnchor: [25, 40],
        popupAnchor: [2, -40]
    });

    useEffect(() => {
        if (id !== undefined) {
            let params = {
                date: selectedDate
            };

            apiGetDeviceRouteDetails(id, params).then(
                ({data}) => {

                    while(pos.length > 0) {
                        pos.pop();
                    }
                    for (const datum of data.data) {
                        pos.push([Number(datum.lat), Number(datum.lng)]);
                    }
                    setData(data);
                    setPos(pos);
                })
                .catch(() => {
                });
        }
    }, [selectedDate, pos]);

    return (
        <Card>
            {pos.length > 3 &&
            <CardBody>
                <MapContainer
                    style={{height: "480px", width: "100%"}}
                    zoom={10}
                    center={[data.centerLat, data.centerLng]}
                    whenReady={setMap}
                    scrollWheelZoom={true}>

                    <Recenter lat={data.centerLat} lng={data.centerLng}/>

                    <FeatureGroup>

                        <Marker position={[data.data[0].lat, data.data[0].lng]} icon={startDot}>
                            <Popup>
                                <b>START</b><br/><br/>
                                <b>TIME: </b>{Utils.getFormattedTime(data.data[0].time, "DD MMM, hh:mm a")} <br/>
                                <b>SPEED: </b>{data.data[0].speed} <br/>
                                <b>ODOMETER: </b>{data.data[0].odo}<br/>
                            </Popup>
                        </Marker>

                        {data.data?.map((mark, i) => (
                            <Marker key={i} position={[mark.lat, mark.lng]} icon={redDot}>
                                <Popup>
                                    <b>TIME: </b>{Utils.getFormattedTime(mark.time, "DD MMM, hh:mm a")} <br/>
                                    <b>SPEED: </b>{mark.speed} <br/>
                                    <b>ODOMETER: </b>{mark.odo}<br/>
                                </Popup>
                            </Marker>
                        ))}

                        <Marker position={[data.data[pos.length - 1].lat, data.data[pos.length - 1].lng]} icon={endDot}>
                            <Popup>

                                <b>END</b><br/><br/>
                                <b>TIME: </b>{Utils.getFormattedTime(data.data[pos.length - 1].time, "DD MMM, hh:mm a")} <br/>
                                <b>SPEED: </b>{data.data[pos.length - 1].speed} <br/>
                                <b>ODOMETER: </b>{data.data[pos.length - 1].odo}<br/>
                            </Popup>
                        </Marker>

                        <Polyline positions={pos} color="#33A5FF"/>
                    </FeatureGroup>

                    <ReactLeafletGoogleLayer apiKey={Constants.GOOGLE_MAPS_KEY}/>
                </MapContainer>
            </CardBody>
            }
        </Card>
    );
}
