import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {isMobile} from "react-device-detect";
import {DEVICE} from "../../crud";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {ActionsColumnFormatter} from "../../Common/column-formatters/ActionsColumnFormatter";
import {DeviceFilter} from "./DeviceFilter";
import {HrefColumnFormatter} from "../../Common/column-formatters/HrefColumnFormatter";
import {SingleGroupLabelFormatter} from "../../Common/column-formatters/SingleGroupLabelFormatter";

export function DevicesCard({history, reload}) {

    const [tableFilterData, setTableFilterData] = useState(0);

    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);

    function filter(filterData) {
        setTableFilterData(filterData);
    }

    useEffect(() => {
        setTitleButtonGotoCallback(newButtonClick, "ADD NEW DEVICE");
    }, [auth]);

    function newButtonClick() {
        history.push("/devices/new");
    }

    function openEditDialog(id) {
        history.push(`/devices/${id}/edit`);
    }

    function openChild(id) {
        history.push("/deviceDetails/" + id);
    }

    const columns = [
        {
            dataField: "imei",
            text: "IMEI",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            hidden: false,
            formatter: HrefColumnFormatter,
            formatExtraData: {
                openChild: openChild,
                type: 1
            },
        },
        {
            dataField: "simno",
            text: "SIM No.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "vehicleNo",
            text: "Vehicle No.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "batteryNo",
            text: "Battery No.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "groupDetails",
            text: "Group",
            sort: true,
            sortCaret: sortCaret,
            formatter: SingleGroupLabelFormatter,
            headerSortingClasses,
        },
        {
            dataField: "createdDate",
            text: "Created Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "fwver",
            text: "Firmware Ver.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "hwver",
            text: "Hardware Ver.",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "product",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                buttons:
                    [
                        {
                            name: "Edit",
                            color: "primary",
                            icon: "/media/svg/icons/Communication/Write.svg",
                            method: openEditDialog
                        }
                    ],
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
            hidden: isMobile
        },
    ];

    return (
        <Card>
            <CardBody>
                <DeviceFilter filter={filter}/>
                <GenericTable reload={reload}
                              tableFilterData={tableFilterData}
                              defaultData={defaultList}
                              columns={columns}
                              url={DEVICE}
                />
            </CardBody>
        </Card>
    );
}
