/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useContext} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import {AuthContext} from "../../../../../app/pages/Common/auth/context/AuthState";

export function AsideMenuList({layoutProps}) {
    const location = useLocation();
    const {isSuperAdmin} = useContext(AuthContext);
    const {isAdmin} = useContext(AuthContext);
    const {getOrgType} = useContext(AuthContext);

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? " menu-item-active menu-item-open " : "";
    };

    return (<>
            {isSuperAdmin() && <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item ${getMenuItemActive("/dashboard")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/></span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/admin/devices")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/devices">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Devices/Router2.svg")}/></span>
                        <span className="menu-text">Devices</span>
                    </NavLink>
                </li>


                <li className={`menu-item ${getMenuItemActive("/orgs")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/orgs">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/></span>
                        <span className="menu-text">Orgs</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/admin/users")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/admin/users">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/></span>
                        <span className="menu-text">Users</span>
                    </NavLink>
                </li>

            </ul>}

            {!isSuperAdmin() && <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item ${getMenuItemActive("/dashboard")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/></span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/trips")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/trips">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Media/Repeat-one.svg")}/></span>
                        <span className="menu-text">Trips</span>
                    </NavLink>
                </li>

                <li className="menu-section">
                    <h4 className="menu-text">REPORTS</h4>
                    <i className="menu-icon ki ki-bold-more-hor icon-md"/>
                </li>

                <li className={`menu-item ${getMenuItemActive("/downloadReports")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/downloadReports">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Incoming-box.svg")}/></span>
                        <span className="menu-text">Download Reports</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/viewMore?groups=&title=All%20Devices&seriesName=All%20Devices">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")}/></span>
                        <span className="menu-text">All Vehicles</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/viewMore?groups=&title=Moving&seriesName=Moving">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")}/></span>
                        <span className="menu-text">Moving Vehicles</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/viewMore?groups=&title=Stopped&seriesName=Stopped">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Code/Stop.svg")}/></span>
                        <span className="menu-text">Stopped Vehicles</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/viewMore?groups=&title=Idle&seriesName=Idle">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Food/Coffee1.svg")}/></span>
                        <span className="menu-text">Idle Vehicles</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/viewMore?groups=&title=Disconnected&seriesName=Disconnected">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}/></span>
                        <span className="menu-text">Disconnected Vehicles</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link"
                             to="/viewMore?groups=&title=Non%20Coverage&seriesName=Non%20Coverage">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Devices/Wi-fi.svg")}/></span>
                        <span className="menu-text">Non Coverage Vehicles</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/groups")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/movementReport">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Media/Shuffle.svg")}/></span>
                        <span className="menu-text">Movement Report</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("/overSpeedReport")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/overSpeedReport">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}/></span>
                        <span className="menu-text">Overspeeding Report</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link"
                             to="/viewMore?groups=&title=Moving/Idle%20Vehicles&seriesName=Moving/Idle%20Vehicles">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Shopping/MC.svg")}/></span>
                        <span className="menu-text">Moving/Idle Report</span>
                    </NavLink>
                </li>

                <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                    <NavLink className="menu-link"
                             to="/viewMore?groups=&title=Distance%20Travelled&seriesName=Distance%20Travelled">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")}/></span>
                        <span className="menu-text">Distance Travelled Report</span>
                    </NavLink>
                </li>

                {getOrgType() !== 1 && <>
                    <li className="menu-section">
                        <h4 className="menu-text">BATTERY REPORTS</h4>
                        <i className="menu-icon ki ki-bold-more-hor icon-md"/>
                    </li>

                    <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/viewMore?groups=&title=Charging&seriesName=Charging">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}/></span>
                            <span className="menu-text">Charging Devices</span>
                        </NavLink>
                    </li>

                    <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/viewMore?groups=&title=DisCharging&seriesName=DisCharging">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-down.svg")}/></span>
                            <span className="menu-text">DisCharging Devices</span>
                        </NavLink>
                    </li>

                    <li className={`menu-item ${getMenuItemActive("")}`} aria-haspopup="true">
                        <NavLink className="menu-link"
                                 to="/viewMore?groups=&title=Battery%20Idle&seriesName=Battery%20Idle">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Code/Puzzle.svg")}/></span>
                            <span className="menu-text">Idle Devices</span>
                        </NavLink>
                    </li>
                </>}

                {/*<li className={`menu-item ${getMenuItemActive("/groups")}`} aria-haspopup="true">*/}
                {/*    <NavLink className="menu-link" to="/groups">*/}
                {/*                <span className="svg-icon menu-icon"><SVG*/}
                {/*                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}/></span>*/}
                {/*        <span className="menu-text">Stoppage Report</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {/*<li className={`menu-item ${getMenuItemActive("/groups")}`} aria-haspopup="true">*/}
                {/*    <NavLink className="menu-link" to="/groups">*/}
                {/*                <span className="svg-icon menu-icon"><SVG*/}
                {/*                    src={toAbsoluteUrl("/media/svg/icons/Media/Pause.svg")}/></span>*/}
                {/*        <span className="menu-text">Idling Report</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {/*<li className={`menu-item ${getMenuItemActive("/groups")}`} aria-haspopup="true">*/}
                {/*    <NavLink className="menu-link" to="/groups">*/}
                {/*                <span className="svg-icon menu-icon"><SVG*/}
                {/*                    src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}/></span>*/}
                {/*        <span className="menu-text">Utilization Report</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                <li className="menu-section">
                    <h4 className="menu-text">SETTINGS</h4>
                    <i className="menu-icon ki ki-bold-more-hor icon-md"/>
                </li>

                <li className={`menu-item ${getMenuItemActive("/devices")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/devices">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Devices/Router2.svg")}/></span>
                        <span className="menu-text">Devices</span>
                    </NavLink>
                </li>

                {/*<li className={`menu-item ${getMenuItemActive("/groups")}`} aria-haspopup="true">*/}
                {/*    <NavLink className="menu-link" to="/groups">*/}
                {/*                <span className="svg-icon menu-icon"><SVG*/}
                {/*                    src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}/></span>*/}
                {/*        <span className="menu-text">Service Remainders</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}

                {isAdmin() && <li className={`menu-item ${getMenuItemActive("/mulCommands")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/mulCommands">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")}/></span>
                        <span className="menu-text">Commands</span>
                    </NavLink>
                </li>}

                {isAdmin() &&
                <li className={`menu-item ${getMenuItemActive("/geofence")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/geofence">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Food/Orange.svg")}/></span>
                        <span className="menu-text">Geo Fence</span>
                    </NavLink>
                </li>
                }

                {isAdmin() && <li className={`menu-item ${getMenuItemActive("/groups")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/groups">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-3d.svg")}/></span>
                        <span className="menu-text">Groups</span>
                    </NavLink>
                </li>}

                {isAdmin() && <li className={`menu-item ${getMenuItemActive("/users")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/users">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/></span>
                        <span className="menu-text">Users</span>
                    </NavLink>
                </li>}

                {isAdmin() && <li className={`menu-item ${getMenuItemActive("/apikey")}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/apiKey">
                                <span className="svg-icon menu-icon"><SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}/></span>
                        <span className="menu-text">API Key</span>
                    </NavLink>
                </li>}

            </ul>}
    </>);
}
