import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { showToast, TOAST_SUCCESS } from "../../Utils/toast/Toast";
import {
    apiGetAllGroupsForOrg,
    apiGetAllOrg,
    apiSuperMoveDevice
} from "../../crud";
import { defaultGroups, defaultOrg } from "../../Common/generic/table/InitialStates";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";

export function AdminDeviceMoveDialog({ id, show, onHide, reloadData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [allOrgs, setAllOrgs] = useState(defaultOrg);
    const [allGroups, setAllGroups] = useState(defaultGroups);

    useEffect(() => {
        setIsLoading(true);
        apiGetAllOrg()
            .then(({ data }) => setAllOrgs(data.entities))
            .catch(() => {})
            .finally(() => setIsLoading(false));
    }, []);

    const fetchGroupsForOrg = (orgId) => {
        setIsLoading(true);
        apiGetAllGroupsForOrg(orgId)
            .then(({ data }) => setAllGroups(data.entities))
            .catch(() => {})
            .finally(() => setIsLoading(false));
    };

    const formik = useFormik({
        initialValues: { org: '', groups: '' },
        enableReinitialize: true,
        onSubmit: (device) => {
            moveDevice(device);
        }
    });

    const handleOrgChange = (option) => {
        formik.setFieldValue('org', option);
        fetchGroupsForOrg(option.id);
    };

    const moveDevice = (device) => {
        setIsLoading(true);

        // Constructing the parameters for the API call
        let params = {
            deviceId: id,
            newOrgId: device.org.id,
            newGroupId: device.groups.id
        };

        apiSuperMoveDevice(params)
            .then(() => {
                showToast("Device is Moved!", TOAST_SUCCESS);
                reloadData();
            })
            .catch(() => {})
            .finally(() => {
                setIsLoading(false);
                onHide();
            });
    };

    return (
        <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-sm">
            {isLoading && <ModalProgressBar />}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    Move Device to different Org
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right" onSubmit={formik.handleSubmit}>
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-6">
                                <Form.Label>Select Organisation</Form.Label>
                                <AsyncSelect
                                    isMulti={false}
                                    defaultOptions={allOrgs}
                                    value={formik.values.org}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={handleOrgChange}
                                />
                                <Form.Text className="text-muted">Please select new Organisation</Form.Text>
                            </Form.Group>

                            <Form.Group className="col-lg-6">
                                <Form.Label>Select Group</Form.Label>
                                <AsyncSelect
                                    isMulti={false}
                                    defaultOptions={allGroups}
                                    value={formik.values.groups}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => formik.setFieldValue('groups', option)}
                                />
                                <Form.Text className="text-muted">Please select User's Group</Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Modal.Footer>
                            <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                                Cancel
                            </button>
                            <> </>
                            <button type="submit" className="btn btn-primary btn-elevate">
                                Move Now
                            </button>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, The Device is moving to the destination Org</span>}
            </Modal.Body>
        </Modal>
    );
}
