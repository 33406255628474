import React, {useState} from "react";
import {AdminDevicesCard} from "./AdminDevicesCard";
import {Route} from "react-router-dom";
import {AdminDeviceEditDialog} from "./AdminDeviceEditDialog";
import {AdminDeviceDeleteDialog} from "./AdminDeviceDeleteDialog";
import {AdminDeviceMoveDialog} from "./AdminDeviceMoveDialog";


export function AdminDevicesPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/admin/devices/new">
                {({history, match}) => (
                    <AdminDeviceEditDialog
                        history={history}
                        show={match != null}
                        onHide={() => {
                            history.push("/admin/devices");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/devices/:id/edit">
                {({history, match}) => (
                    <AdminDeviceEditDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/admin/devices");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/devices/:id/delete">
                {({history, match}) => (
                    <AdminDeviceDeleteDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/admin/devices");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/admin/devices/:id/move">
            {({history, match}) => (
                <AdminDeviceMoveDialog
                    history={history}
                    show={match != null}
                    id={match && match.params.id}
                    onHide={() => {
                        history.push("/admin/devices");
                    }}
                    reloadData={reloadData}
                />
            )}
        </Route>

            <AdminDevicesCard history={history} reload={reload}/>
        </>
    );
}
