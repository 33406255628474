import React from "react";


export function SingleGroupLabelFormatter(cellContent, row) {

    if (row.groupDetails != null) {
        return (
            <span className={`label label-danger label-inline m-1`} style={{margin: 5, cursor: 'pointer'}}>
                   {row.groupDetails.name}
                </span>

        );
    }
}
