import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {apiDeleteGroup} from "../../crud";

export function GroupsDeleteDialog({id, show, onHide, reloadData}) {

    const [isLoading, setIsLoading] = useState(false);


    const deleteGroup = () => {
        setIsLoading(true);
        apiDeleteGroup(id).then(() => {
            showToast("Group is Deleted!", TOAST_SUCCESS);
            reloadData();
        }).catch(() => {
            }
        ).finally(() => {
            setIsLoading(false);
            onHide();
        });
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {/*begin::Loading*/}
            {isLoading && <ModalProgressBar/>}
            {/*end::Loading*/}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Group Delete
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading && (
                    <span>Are you sure to permanently delete this Group, All Groups tagged to users will devices will be removed.?</span>
                )}
                {isLoading && <span>Group is getting Deleted...</span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={deleteGroup}
                        className="btn btn-primary btn-elevate"
                    >
                        Delete
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
