import React from "react";
import {BrowserRouter} from "react-router-dom";
import {Routes} from "../app/Routes";
import {I18nProvider} from "../_metronic/i18n";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import {AuthProvider} from "./pages/Common/auth/context/AuthState";
import {ToastContainer} from "react-toastify";
import "chartjs-adapter-moment";
import {Chart, registerables} from "chart.js";

export default function App({basename}) {
    Chart.register(...registerables);
    return (
        /* Provide Redux store */
        <AuthProvider>
            <React.Suspense fallback={<LayoutSplashScreen/>}>
                {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                <BrowserRouter basename={basename}>
                    {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                    <MaterialThemeProvider>
                        {/* Provide `react-intl` context synchronized with Redux state.  */}
                        <I18nProvider>
                            {/* Render routes with provided `Layout`. */}
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable={false}
                                pauseOnHover
                            />
                            <Routes/>
                        </I18nProvider>
                    </MaterialThemeProvider>
                </BrowserRouter>
            </React.Suspense>
        </AuthProvider>
    );
}
