import React, {useState} from "react";
import {GeoFenceCard} from "./GeoFenceCard";
import {Route} from "react-router-dom";
import {GeoFenceDeleteDialog} from "./GeoFenceDeleteDialog";
import {GeoFenceTagDialog} from "./GeoFenceTagDialog";

export function GeoFencePage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <Route path="/geofence/:id/delete">
                {({history, match}) => (
                    <GeoFenceDeleteDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/geofence");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <Route path="/geofence/:id/tagImei">
                {({history, match}) => (
                    <GeoFenceTagDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/geofence");
                        }}
                        reloadData={reloadData}
                    />
                )}
            </Route>

            <GeoFenceCard history={history} reload={reload}/>
        </>
    );
}
