import React from "react";

export function AlertColumnFormatter(cellContent, row) {
    const getLabelCssClasses = (index) => {
        let backgroundColor = '#d1d3e2'; // Info color hex value
        switch (index) {
            case "speed":
                backgroundColor = "#4e73df"; // Primary color hex value
                break;
            case "BatteryDisconnect":
                backgroundColor = "#ce5f10"; // Danger color hex value
                break;
            case "LowBattery":
                backgroundColor = "#6f22e1"; // Warning color hex value
                break;
            case "HighTemperature":
                backgroundColor = "#078072"; // Warning color hex value
                break;
            case "HighRpm":
                backgroundColor = "#3f7219"; // Danger color hex value
                break;
            case "LowFuel":
                backgroundColor = "#15629a"; // Danger color hex value
                break;
            case "ExcessiveIdle":
                backgroundColor = "#e74a3b"; // Danger color hex value
                break;
            default:
                backgroundColor = "#f6c23e"; // Warning color hex value
                break;
        }

        return backgroundColor;
    };

    if (row.alertType != null) {
        return (
            <span key={row.alertType} className="label label-inline" style={{backgroundColor: getLabelCssClasses(row.alertType), color: 'white', fontWeight: 'bold'}}>
                {row.alertType}
                &nbsp;
            </span>
        );
    }
}
