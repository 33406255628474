import React from 'react';
import {headerSortingClasses, sortCaret} from "../../../../../_metronic/_helpers";
import {HrefColumnFormatter} from "../../../Common/column-formatters/HrefColumnFormatter";
import {DateTimeColumnFormatter} from "../../../Common/column-formatters/DateTimeColumnFormatter";
import {GenericTable} from "../../../Common/generic/table/GenericTable";
import {defaultList} from "../../../Common/generic/table/InitialStates";
import { DASHBOARD} from "../../../crud";
import {AlertColumnFormatter} from "../../../Common/column-formatters/AlertColumnFormatter";

export function DashboardAlertsCard({history, title, reload, selectedDate, selectedGroup, imei}) {

    function openChild(id) {
        history.push("/deviceDetails/" + id);
    }

    const columns = [{
        dataField: "imei",
        text: "IMEI",
        sortCaret: sortCaret,
        headerSortingClasses,
        hidden: false,
        formatter: HrefColumnFormatter,
        formatExtraData: {
            openChild: openChild, type: 1
        },
    }, {
        dataField: "vehicleNumber",
        text: "VEHICLE NUMBER",
        sortCaret: sortCaret,
        headerSortingClasses,
    }, {
        dataField: "time",
        text: "TIME",
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: DateTimeColumnFormatter,
    }, {
        dataField: "alertType",
        text: "ALERT TYPE",
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: AlertColumnFormatter
    },{
        dataField: "data",
        text: "DATA",
        sortCaret: sortCaret,
        headerSortingClasses,
    }];

    let groups = "";
    for (const group in selectedGroup) {
        groups = groups + selectedGroup[group].id + ",";
    }

    return (
            <>
                <div className="bg-white p-4">
                    <b>{title} </b>
                </div>

                <GenericTable reload={reload}
                              tableFilterData={{
                                  "date": selectedDate,
                                  "groups": groups,
                                  "imei": imei,
                                  "seriesName": "Vehicle Alerts",
                                  "title": "Vehicle Alerts",
                                  "page": 1,
                                  "sizePerPage": 10,
                                  "currentIndex": 0
                              }}
                              defaultData={defaultList}
                              columns={columns}
                              recordCount={10}
                              url={DASHBOARD + "/evAllDatalistData"}
                />
            </>
        );
}
