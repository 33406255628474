import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import {MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
import * as serviceWorker from './serviceWorker';
import setupAxios from "./redux/setupAxios";
import axios from "axios";

const {PUBLIC_URL} = process.env;


setupAxios(axios);

ReactDOM.render(
    <MetronicI18nProvider>
        <MetronicLayoutProvider>
            <MetronicSubheaderProvider>
                <MetronicSplashScreenProvider>
                    <App basename={PUBLIC_URL}/>
                </MetronicSplashScreenProvider>
            </MetronicSubheaderProvider>
        </MetronicLayoutProvider>
    </MetronicI18nProvider>,
    document.getElementById("root")
);

serviceWorker.register();
