import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {apiGenerateAPIKey} from "../../crud";

export function ApiKeyNewDialog({id, show, onHide, reloadData}) {

    const [isLoading, setIsLoading] = useState(false);


    const generateAPIKey = () => {
        setIsLoading(true);
        apiGenerateAPIKey().then(() => {
            showToast("API Key is Created!", TOAST_SUCCESS);
            reloadData();
        }).catch(() => {
        }).finally(() => {
            setIsLoading(false);
            onHide();
        });
    };

    return (<Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
    >
        {/*begin::Loading*/}
        {isLoading && <ModalProgressBar/>}
        {/*end::Loading*/}
        <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
                Create API Key
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {!isLoading && (<span>Do you want to create a new API Key?</span>)}
            {isLoading && <span>API Key is getting generated...</span>}
        </Modal.Body>
        <Modal.Footer>
            <div>
                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Cancel
                </button>
                <> </>
                <button
                    type="button"
                    onClick={generateAPIKey}
                    className="btn btn-primary btn-elevate"
                >
                    Create
                </button>
            </div>
        </Modal.Footer>
    </Modal>);
}
