import React, {useState} from "react";

export function SuperAdminDashboardPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (
        <>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">

                    <div className="fw-bolder bg-white card gs-0 gy-4 p-4">
                        <b>Live Fleet Data</b>
                    </div>
                    <br/>

                    <div className="row">
                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src="https://g.trakmatesolutions.com/d-solo/i37ZSCGVz/version1?orgId=2&refresh=10s&theme=light&panelId=6"
                                width="100%" height="400px" frameBorder="0"/>
                        </div>


                        <div className="col-lg-6 col-xxl-6">
                            <iframe
                                src="https://g.trakmatesolutions.com/d-solo/i37ZSCGVz/version1?orgId=2&refresh=10s&theme=light&panelId=9"
                                width="100%" height="400px" frameBorder="0"/>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
