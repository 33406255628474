import React, {useEffect, useState} from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {FeatureGroup, MapContainer, Marker, Polyline, useMap} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import * as Constants from "../../Constants";
import * as moment from "moment";
import L, {LatLngBounds} from "leaflet";
import {apiGetDeviceForTripRoutePlayback} from "../../crud";
import {Button} from "@material-ui/core";
import 'rc-slider/assets/index.css';

export function TripRoutePlaybackPage({history, id}) {
    const [map, setMap] = useState(null);
    const [vehicleNumber, setVehicleNumber] = useState("NA");
    const [imei, setImei] = useState("NA");
    const [pos, setPos] = useState([]);
    const [pointIndex, setPointIndex] = useState(0);
    const [receivedData, setReceivedData] = useState([]);
    const [paused, setPaused] = useState(true);

    const redDot = new L.Icon({
        iconUrl: "/media/png/red-dot.png",
        iconSize: [5, 5],
        iconAnchor: [5, 5],
        popupAnchor: [2, -40]
    });

    const greenDot = new L.Icon({
        iconUrl: "/media/png/green-dot.png",
        iconSize: [25, 25],
        iconAnchor: [15, 15],
        popupAnchor: [2, -40]
    });

    useEffect(() => {
        populateData();
    }, [id]);

    function populateData() {

        apiGetDeviceForTripRoutePlayback(id).then(
            ({data}) => {
                setReceivedData(data.data);
                setVehicleNumber(data.vehicleNumber)
                setImei(data.imei)
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        const intervalId = setInterval(() => {

            if (paused === false) {

                if (receivedData.length > 2 && receivedData.length > pointIndex) {
                    setPos(prevItems => [...prevItems, [receivedData[pointIndex].lat, receivedData[pointIndex].lng]]);
                    setPointIndex(pointIndex + 1)
                }
            }
        }, 100);
        return () => clearInterval(intervalId);
    }, [receivedData, pointIndex]);

    const Recenter = ({lat, lng}) => {
        const map = useMap();
        useEffect(() => {
            const bounds = new LatLngBounds(pos);
            map.fitBounds(bounds);
        }, [lat, lng]);
        return null;
    };

    function buttonClicked(type) {

        if (type === 3) { // RESTART
            setPointIndex(0);
            setPos([]);
        }

        if (type === 2) { // PAUSE
            setPaused(true);
        }

        if (type === 1) { // PLAY
            setPaused(false);
            setPointIndex(pointIndex + 1)
        }
    }

    return (
        <>
            <div className="row">

                <div className="col-lg-12 col-xxl-4">
                    <Card>
                        <CardBody>
                            <Button onClick={() => buttonClicked(1)}
                                    className="btn btn-sm btn-light-primary font-weight-bold ml-2 mr-2">
                                <span className="far fa-play-circle">&nbsp;&nbsp;PLAY</span>
                            </Button>

                            <Button onClick={() => buttonClicked(2)}
                                    className="btn btn-sm btn-light-danger font-weight-bold ml-2 mr-2">
                                <span className="far fa-pause-circle">&nbsp;&nbsp;PAUSE</span>
                            </Button>

                            <Button onClick={() => buttonClicked(3)}
                                    className="btn btn-sm btn-light-success font-weight-bold ml-2 mr-2">
                                <span className="fas fa-circle-notch">&nbsp;&nbsp;RESTART</span>
                            </Button>

                            <hr/>
                            <br/><br/>

                            <table className="table table table-bordered">
                                <tbody>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-truck"></i></th>
                                    <td><strong>VEHICLE NUMBER</strong></td>
                                    <td>
                                        <strong>{vehicleNumber}</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-truck"></i></th>
                                    <td><strong>IMEI</strong></td>
                                    <td>
                                        <strong>{imei}</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-neuter"></i></th>
                                    <td><strong>POINT</strong></td>
                                    <td>
                                        <strong>{receivedData && pointIndex} / {receivedData && receivedData.length}</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="far fa-clock"></i></th>
                                    <td><strong>Time</strong></td>
                                    <td><strong>{receivedData[pointIndex] && receivedData[pointIndex].time}</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-tachometer-alt"></i></th>
                                    <td><strong>Speed</strong></td>
                                    <td><strong>{receivedData[pointIndex] && receivedData[pointIndex].speed}</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <th width='10px' scope="row"><i className="fas fa-road"></i></th>
                                    <td><strong>Odometer</strong></td>
                                    <td><strong>{receivedData[pointIndex] && receivedData[pointIndex].odo}</strong></td>
                                </tr>
                                </tbody>
                            </table>

                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-12 col-xxl-8">
                    <Card>
                        <CardBody>
                            <MapContainer
                                style={{height: "680px", width: "100%"}}
                                zoom={10}
                                whenReady={setMap}
                                scrollWheelZoom={true}>


                                <ReactLeafletGoogleLayer apiKey={Constants.GOOGLE_MAPS_KEY}/>
                                <FeatureGroup>
                                    {pos.map((mark, i) => (
                                        <p key={i}>

                                            <Recenter lat={mark[0]} lng={mark[1]}/>
                                            <Marker key={i} position={[mark[0], mark[1]]} icon={redDot}>

                                            </Marker>
                                        </p>
                                    ))}

                                    <Polyline positions={pos} color="#33A5FF"/>
                                </FeatureGroup>

                                <FeatureGroup>
                                    {pos[1] &&
                                    <Marker position={[pos[pos.length-1][0], pos[pos.length-1][1]]}
                                            icon={greenDot}/>
                                    }
                                </FeatureGroup>

                            </MapContainer>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
}
