import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { apiGetCanData, apiDownloadCanData } from "../../crud";
import { Tabs, Tab, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function CanDataPage({ history, id }) {
    const groups = [
        { id: 1, name: "AMT" },
        { id: 2, name: "APS_FastSteer" },
        { id: 3, name: "Autosteer" },
        { id: 4, name: "Engine_Term4_Stage5" },
        { id: 5, name: "Gen3p5_IC_and_Telematics_v1p5" },
        { id: 6, name: "ICE_TFT" },
        { id: 7, name: "PST" },
    ];

    const [canData, setCanData] = useState(
        Object.fromEntries(groups.map(group => [group.id, {}]))
    );
    const [activeGroup, setActiveGroup] = useState(groups[0].id.toString());
    const [showDateModal, setShowDateModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [downloadingGroup, setDownloadingGroup] = useState(null);

    useEffect(() => {
        fetchAllCanData();
        const intervalId = setInterval(fetchAllCanData, 60000); // Refresh every minute
        return () => clearInterval(intervalId);
    }, [id]);

    const fetchAllCanData = () => {
        groups.forEach(group => {
            apiGetCanData(group.id, id)
                .then(({ data }) => {
                    setCanData(prevState => ({
                        ...prevState,
                        [group.id]: data
                    }));
                })
                .catch((error) => {
                    console.error(`Error fetching CAN data for group ${group.name}:`, error);
                });
        });
    };

    const handleDownload = (groupId, groupName) => {
        setDownloadingGroup({ id: groupId, name: groupName });
        setShowDateModal(true);
    };

    const handleDateConfirm = () => {
        setShowDateModal(false);
        if (downloadingGroup) {
            apiDownloadCanData(downloadingGroup.id, id, selectedDate)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `can_data_${downloadingGroup.name.toLowerCase().replace(/ /g, '_')}_${selectedDate.toISOString().split('T')[0]}.csv`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`Error downloading CAN data for ${downloadingGroup.name}:`, error);
                });
        }
    };

    const renderDataTable = (groupId) => {
        if (Object.keys(canData[groupId]).length === 0) {
            return (
                <div className="text-center p-5">
                    <p className="text-muted">No data available for {groups.find(g => g.id === groupId).name}</p>
                </div>
            );
        }

        return (
            <table className="table table-bordered">
                <tbody>
                {Object.entries(canData[groupId]).map(([key, value]) => (
                    <tr key={key}>
                        <th>{key.replace(/_/g, ' ')}</th>
                        <td>{value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <Card>
                    <CardBody>
                        <h3 className="card-title font-weight-bolder text-dark mb-4">Device CAN Data</h3>
                        <Tabs
                            activeKey={activeGroup}
                            onSelect={(k) => setActiveGroup(k)}
                            className="mb-3"
                        >
                            {groups.map(group => (
                                <Tab eventKey={group.id.toString()} title={group.name} key={group.id}>
                                    {Object.keys(canData[group.id]).length > 0 && (
                                        <div className="d-flex justify-content-end mb-3">
                                            <Button
                                                variant="primary"
                                                onClick={() => handleDownload(group.id, group.name)}
                                            >
                                                Download {group.name}
                                            </Button>
                                        </div>
                                    )}
                                    {renderDataTable(group.id)}
                                </Tab>
                            ))}
                        </Tabs>
                    </CardBody>
                </Card>
            </div>
            <Modal show={showDateModal} onHide={() => setShowDateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Date for Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        maxDate={new Date()}
                        className="form-control"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleDateConfirm}>
                        Download
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}