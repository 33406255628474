import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {useFormik} from "formik";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";
import {apiCommands} from "../../crud";

export function NewCommandDialog({id, show, onHide, reloadData}) {
    const [commandStr, setCommandStr] = useState({command: ""});
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("COMMAND");
    const formik = useFormik({
        initialValues: commandStr,
        enableReinitialize: true,
        onSubmit(command) {
            setIsLoading(true);
            apiCommands(command, id).then(() => {
                setIsLoading(false);
                onHide();
                reloadData();
                showToast("Command Sent!", TOAST_SUCCESS);
            }).catch(() => {
                setIsLoading(false);
            });

        }
    });

    return (
        <Modal
            size="sm"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm">

            {isLoading && <ModalProgressBar/>}

            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="overlay">
                {!isLoading && (
                    <Form className="form form-label-right">
                        <Form.Row className="form-group row">
                            <Form.Group className="col-lg-12">
                                <Form.Label>Command</Form.Label>
                                <Form.Control
                                    id="command"
                                    placeholder="Enter Command"
                                    onChange={formik.handleChange}
                                    value={formik.values.command}
                                />
                                <Form.Text className="text-muted">Please enter the Command, Command will be sent
                                    to {id}</Form.Text>
                            </Form.Group>

                        </Form.Row>

                        <Modal.Footer>
                            <div>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate">
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    className="btn btn-primary btn-elevate">
                                    Send Command
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Fetching Information from Server</span>}
            </Modal.Body>
        </Modal>
    );
}
