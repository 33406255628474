import React, {useEffect, useState} from "react";
import {BarChartCard} from "./BarChartCard";
import {apiGetReportChartData} from "../crud";
import {REPORT_MOVEMENT_REPORT} from "../Constants";
import {ReportsDevicesListCard} from "./ReportsDevicesListCard";
import {headerSortingClasses, sortCaret} from "../../../_metronic/_helpers";
import {HrefColumnFormatter} from "../Common/column-formatters/HrefColumnFormatter";

export function MovementReportsPage({history, id}) {
    const [reload, setReload] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedDate, setSelectedDate] = useState('NA');

    const [data, setData] = useState({});

    const columns = [{
        dataField: "imei",
        text: "IMEI",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        hidden: false,
        formatter: HrefColumnFormatter,
        formatExtraData: {
            openChild: openChild, type: 1
        },
    }, {
        dataField: "distanceTravelled",
        text: "DISTANCE TRAVELLED",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
    }, {
        dataField: "odometer",
        text: "ODOMETER",
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
    }
    ];

    function openChild(id) {
        history.push("/deviceDetails/" + id);
    }

    useEffect(() => {

        apiGetReportChartData(REPORT_MOVEMENT_REPORT, (currentIndex)).then(({data}) => {
            setData(data)
            setSelectedDate(data.startDate + ' - ' + data.endDate)
        })
            .catch(() => {
            });

    }, [currentIndex]);

    function reloadData() {
        setReload(reload + 1);
    }

    function goNext() {
        if (currentIndex === 0) {
            return;
        }

        setCurrentIndex(currentIndex + 1)
    }

    function goPrevious() {
        setCurrentIndex(currentIndex - 1)
    }

    return (<div className="app">
        <div id="kt_app_content" className="app-content flex-column-fluid mb-3">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="card card-custom card-stretch ">
                    <div className="align-items-center flex-wrap py-5 h-auto">
                        <div className="float-right mr-6">
                            <a className="btn btn-icon btn-sm btn-outline-dark btn-hover-primary mr-2 my-1"
                               onClick={goPrevious}>
                                <i className="ki ki-bold-arrow-back icon-xs"/>
                            </a>

                            <a className="btn btn-icon btn-sm btn-outline-dark btn-hover-primary mr-2 my-1"
                               onClick={goNext}>
                                <i className="ki ki-bold-arrow-next icon-xs"/>
                            </a>

                            {(selectedDate)}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="kt_app_content" className="app-content flex-column-fluid mb-3">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="card card-custom card-stretch ">
                    <div className="align-items-center flex-wrap py-5 h-auto">
                        {Object.keys(data).length > 0 && <BarChartCard data={data}/>}
                    </div>
                </div>
            </div>
        </div>

        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-fluid">
                <div className="card card-custom card-stretch ">
                    <div className="align-items-center flex-wrap py-5 h-auto">
                        <ReportsDevicesListCard history={history} reload={reload} reportId={REPORT_MOVEMENT_REPORT} selectedWeek={currentIndex} columns={columns}/>
                    </div>
                </div>
            </div>
        </div>

    </div>);
}
