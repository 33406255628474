import React, {useState} from "react";
import {ApiKeyCard} from "./ApiKeyCard";
import {Route} from "react-router-dom";
import {ApiKeyDeleteDialog} from "./ApiKeyDeleteDialog";
import {ApiKeyNewDialog} from "./ApiKeyNewDialog";


export function ApiKeyPage({history}) {
    const [reload, setReload] = useState(0);

    function reloadData() {
        setReload(reload + 1);
    }

    return (<>
            <Route path="/apiKey/new">
                {({history, match}) => (<ApiKeyNewDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/apiKey");
                        }}
                        reloadData={reloadData}
                    />)}
            </Route>

            <Route path="/apiKey/:id/delete">
                {({history, match}) => (<ApiKeyDeleteDialog
                        history={history}
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push("/apiKey");
                        }}
                        reloadData={reloadData}
                    />)}
            </Route>

            <ApiKeyCard history={history} reload={reload}/>
        </>);
}
