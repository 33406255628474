import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {MapContainer, Marker, Popup, useMap} from 'react-leaflet';
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import {apiGetLatestLocationLog} from "../../crud";
import {defaultLocationLog} from "../../Common/generic/table/InitialStates";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import * as Constants from "../../Constants";
import {RELOAD_INTERVAL} from "../../Constants";

let globalReloadRemainingTime = RELOAD_INTERVAL;

export function DeviceLocation({history, id}) {
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const [data, setData] = useState(defaultLocationLog);
    const subHeader = useSubheader();
    const [reload, setReload] = useState(0);
    const [timerForReload, setTimerForReload] = useState(RELOAD_INTERVAL);
    const [isLoading, setIsLoading] = useState(false);

    const Recenter = ({lat, lng}) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    };

    function reloadData() {
        setReload(reload => (reload + 1));
        setTimerForReload(RELOAD_INTERVAL);
    }

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Device Location");
        subHeader.setBreadcrumbs([
            {
                "pathname": "/devices",
                "title": "Devices"
            },
            {
                "title": "Device Location"
            }
        ]);

    }, [auth]);

    React.useEffect(() => {
        const timer = window.setInterval(() => {
            resetTimerForReload();
        }, 1000);

        return () => window.clearInterval(timer);
    }, []);

    function resetTimerForReload() {
        globalReloadRemainingTime--;

        if (globalReloadRemainingTime <= 0) {
            globalReloadRemainingTime = RELOAD_INTERVAL;
            reloadData();
        } else {
            setTimerForReload(globalReloadRemainingTime => (globalReloadRemainingTime - 1));
        }
    }

    useEffect(() => {
        setIsLoading(true);
        apiGetLatestLocationLog(id).then(
            ({data}) => {
                setData(data);
            }).catch(() => {
                setIsLoading(false);
            }).finally(() => {
            setIsLoading(false);
        });

    }, [reload]);

    return (
        <>
            <div className="row">
                <p className="col-lg-12">
                    {isLoading &&
                    <button
                        type="button"
                        className="btn btn-sm btn-light-primary font-weight-bold ml-2 spinner spinner-right spinner-darker-primary float-right">
                        <span className="flaticon2-refresh"> &nbsp;&nbsp;Reloading Map...</span>
                    </button>
                    }

                    {!isLoading &&
                    <button
                        type="button"
                        onClick={reloadData}
                        className="btn btn-sm btn-light-primary font-weight-bold ml-2 float-right">
                        <span
                            className="flaticon2-refresh"> &nbsp;&nbsp;Reload Map<small> ({timerForReload} Secs)</small></span>
                    </button>
                    }
                </p>
            </div>
            <Card>
                <CardBody>
                    <MapContainer center={[data.lat, data.lng]} zoom={13} scrollWheelZoom={false}
                                  style={{height: '600px'}}>
                        <Recenter lat={data.lat} lng={data.lng}/>
                        <Marker position={[data.lat, data.lng]}>
                            <Popup>
                                <b>IMEI: </b>{data.imei} <br/>
                                <b>ODOMETER: </b>{data.odo} Kms<br/>
                            </Popup>
                        </Marker>
                        <ReactLeafletGoogleLayer apiKey={Constants.GOOGLE_MAPS_KEY}/>

                    </MapContainer>
                </CardBody>
            </Card>
        </>
    );
}
