import React, {useContext, useEffect, useState} from "react";
import {Card, CardBody,} from "../../../../_metronic/_partials/controls";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {COMMANDS} from "../../crud";
import {GenericTable} from "../../Common/generic/table/GenericTable";
import {DateTimeColumnFormatter} from "../../Common/column-formatters/DateTimeColumnFormatter";
import {useSubheader} from "../../../../_metronic/layout";

export function MulCommandsCard({id, history, reload}) {
    const subHeader = useSubheader();

    const [tableFilterData, setTableFilterData] = useState(0);
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);

    function filter(filterData) {
        setTableFilterData(filterData);
    }

    useEffect(() => {
        setTitleButtonGotoCallback(() => newButtonClick(), "SEND COMMAND");
        subHeader.setTitle("Commands Sent/Received List");

    }, [auth]);

    function newButtonClick() {
        history.push(`/mulCommands/new/`);
    }

    const columns = [
        {
            dataField: "imei",
            text: "IMEI",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        }, {
            dataField: "type",
            text: "TYPE",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "commandSent",
            text: "Sent Data",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "dataReceived",
            text: "Received Data",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "date",
            text: "Date",
            sort: false,
            sortCaret: sortCaret,
            formatter: DateTimeColumnFormatter,
            headerSortingClasses,
        },
        {
            dataField: "userId",
            text: "User",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
    ];

    return (
        <>
            <Card>
                <CardBody>
                    <GenericTable reload={reload}
                                  tableFilterData={tableFilterData}
                                  defaultData={defaultList}
                                  columns={columns}
                                  url={COMMANDS + "/" + id}
                    />
                </CardBody>
            </Card>
        </>
    );
}
