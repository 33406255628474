import React from 'react';
import Chart from 'react-apexcharts';

export function DashboardLineChartCard({title, data, onViewMore, unit=""}) {
    const options = {
        series: data.data,
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        title: {
            text: title,
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: data.xAxixCategories,
        },
        yaxis: {
            labels: {
                formatter: (value) => `${value} ${unit}`
            }
        }
    };

    return (
        <div className="dashboard-line-cahart-card">
            <Chart
                options={options}
                series={options.series}
                type="line"
                width="100%"
            />

            <div className="chart-footer" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px'}}>
                {onViewMore ?
                    <a href="#" onClick={() => onViewMore(title)}>View All Devices &gt;</a>
                    : null
                }
            </div>
        </div>
    );
}
