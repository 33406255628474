/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useContext} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import {AuthContext} from "../../../../../app/pages/Common/auth/context/AuthState";

export function HeaderMenu({layoutProps}) {
    const location = useLocation();
    const {isSuperAdmin} = useContext(AuthContext);
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    };

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="svg-icon menu-icon"><SVG
                        src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/></span>
                    <span className="menu-text">Dashboard</span>
                </NavLink>
            </li>

            {!isSuperAdmin() &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/devices')}`}>
                    <NavLink className="menu-link" to="/devices">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Devices/Router2.svg")}/></span>
                        <span className="menu-text">Devices</span>
                    </NavLink>
                </li>
            }

            {!isSuperAdmin() &&
                <li className={`menu-item menu-item-rel ${getMenuItemActive('/trips')}`}>
                    <NavLink className="menu-link" to="/trips">
                        <span className="svg-icon menu-icon"><SVG
                            src={toAbsoluteUrl("/media/svg/icons/Media/Repeat-one.svg")}/></span>
                        <span className="menu-text">Trips</span>
                    </NavLink>
                </li>
            }
        </ul>
    </div>;
}
