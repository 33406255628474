import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";
import {Form} from "react-bootstrap";
import {useFormik} from "formik";
import {showToast, TOAST_FAILURE, TOAST_SUCCESS} from "../../../Utils/toast/Toast";
import {apiResetPassword} from "../../../crud";


export function UserPasswordDialog({id, show, onHide, reloadData}) {

    const [isLoading, setIsLoading] = useState(false);
    const {handleSubmit, handleChange} = useFormik({
        initialValues: {
            password1: "",
            password2: ""
        },
        onSubmit(values) {
            if (("" + values.password1).length <= 0 || ("" + values.password2).length <= 0) {
                showToast("Passwords cannot be empty.", TOAST_FAILURE);
            } else if (("" + values.password1).length <= 5 || ("" + values.password2).length <= 5) {
                showToast("Passwords length cannot be less than 5 characters.", TOAST_FAILURE);
            } else if (("" + values.password1).localeCompare("" + values.password2) !== 0) {
                showToast("Both the passwords did not match", TOAST_FAILURE);
            } else {
                setIsLoading(true);
                apiResetPassword(id, {password: values.password1}).then(() => {
                    showToast("User's Password is reset!", TOAST_SUCCESS);
                    reloadData();
                }).catch(() => {
                    }
                ).finally(() => {
                    setIsLoading(false);
                    onHide();
                });
            }
        }
    });

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {/*begin::Loading*/}
            {isLoading && <ModalProgressBar/>}
            {/*end::Loading*/}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Reset Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {!isLoading && (
                    <Form>
                        <Form.Group controlId="formBasicPassword-1">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter Password"
                                name="password1"
                                onChange={handleChange}
                            />
                            <Form.Text className="text-muted">
                                Password will be encrypted and saved in database.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword-2">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password2"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                )}
                {isLoading && <span>Please Wait, Password is being sent to server...</span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate">
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-elevate">
                        Reset Password
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
