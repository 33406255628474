import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {apiCommands} from "../../crud";
import {showToast, TOAST_SUCCESS} from "../../Utils/toast/Toast";

export function CommandsButtonCard({id, reload}) {

    function sendCommand(command) {
        command = {command: command};

        apiCommands(command, id).then(() => {
            showToast("Command Sent!", TOAST_SUCCESS);
            reload();
        }).catch(() => {

        });
    }

    return (
        <Card>
            <CardBody>
                <div className="card card-custom card-stretch gutter-b">

                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">Commands</span>
                            <span className="text-muted mt-3 font-weight-bold font-size-sm">Here are the frequently used commands, To Send custom Commands please use the <code>SEND COMMAND</code> Button.</span>
                        </h3>
                    </div>
                </div>
                <br/>


                <a href="#" className="btn btn-light-danger mr-3" onClick={() => {
                    if (window.confirm('Are you sure you wish to send this command?')) sendCommand("SET IMMOBILIZE ENABLE")
                }}>
                    <i className="flaticon-cancel"></i>IMMOBILIZE
                </a>

                <a href="#" className="btn btn-light-primary mr-3"
                   onClick={() => {
                       if (window.confirm('Are you sure you wish to send this command?')) sendCommand("SET IMMOBILIZE DISABLE")
                   }}>
                    <i className="flaticon-like"></i>MOBILIZE
                </a>

                <a href="#" className="btn btn-light-warning mr-3" onClick={() => {
                    if (window.confirm('Are you sure you wish to send this command?')) sendCommand("SHOW CONFIG")
                }}>
                    <i className="flaticon-information"></i>GET CONFIG
                </a>

            </CardBody>
        </Card>
    );
}
