export default (state, action) => {
    switch (action.type) {
        case 'LOGIN_COMPLETE':
            localStorage.setItem("accessToken", action.payload);

            return {
                ...state,
                auth: {
                    accessToken: action.payload,
                    fullName: "",
                    isLoggedin: true,
                    userId: "",
                    profileImage: "",
                    role: [],
                    orgDeviceType: 2
                }
            };

        case 'LOGOUT_COMPLETE':
            localStorage.setItem("accessToken", null);

            return {
                ...state,
                auth: {
                    accessToken: "",
                    fullName: "",
                    isLoggedin: false,
                    userId: "",
                    profileImage: "",
                    role: [],
                    orgDeviceType: 0
                }
            };

        case 'ME_COMPLETE':
            localStorage.setItem("accessToken", action.payload.accessToken);

            return {
                ...state,
                auth: {
                    accessToken: action.payload.accessToken,
                    fullName: action.payload.fullName,
                    isLoggedin: true,
                    userId: action.payload.userId,
                    profileImage: action.payload.profileImage,
                    role: action.payload.role,
                    orgDeviceType: action.payload.orgDeviceType
                }
            };

        case 'SET_TITLE_BUTTON_NAME':
            return {
                ...state,
                titleButtonName : action.payload
            }
        default:
            return state;
    }
}
