import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../Common/auth/context/AuthState";
import {useSubheader} from "../../../../_metronic/layout";
import {apiGetTripByID} from "../../crud";
import {defaultList} from "../../Common/generic/table/InitialStates";
import {TripCardWithMap} from "./TripCardWithMap";

export function TripsDetailsPage({history, id}) {
    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);
    const subHeader = useSubheader();
    const [trip, setTrip] = useState(defaultList);

    useEffect(() => {
        setTitleButtonGotoCallback(null, "");

        subHeader.setTitle("Trips Details");
        subHeader.setBreadcrumbs([
            {
                "pathname": "/trips",
                "title": "Trips"
            },
            {
                "title": "Trip Details"
            }
        ]);

    }, [auth]);

    useEffect(() => {

        apiGetTripByID(id).then(
            ({data}) => {
                setTrip(data)
            })
            .catch(() => {
            });

    }, []);

    return (
        <>
            <div className="row">
                <TripCardWithMap
                    id={trip.id}
                    history={history}
                    trip={trip}
                />
            </div>
        </>
    );
}
