import React from "react";
import { org_device_types } from "../../Constants";

export function OrgTypeColumnFormatter(cellContent, row) {
    // Find the device type object based on the orgDeviceType id
    const deviceTypeObj = org_device_types.find(device => device.id === row.orgDeviceType);

    // Check if the device type was found and return its type, otherwise return a default message
    const displayText = deviceTypeObj ? deviceTypeObj.type : "Unknown Type";

    return (
        <span>
            {displayText}
        </span>
    );
}
