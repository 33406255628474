import React from "react";
import Chart from "react-apexcharts";

export function BarChartCard({data}) {
    return (<Chart
            options={data.options}
            series={data.series}
            type="bar"
            width="100%"
        />);
}
