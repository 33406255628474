import React, {useContext} from "react";
import {AuthContext} from "./context/AuthState";
import {LayoutSplashScreen} from "../../../../_metronic/layout";

function Logout() {

    const {doLogout} = useContext(AuthContext);
    doLogout();

    return (
        <LayoutSplashScreen/>
    );
}


export default Logout;
