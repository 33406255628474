import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator';
import {NoRecordsFoundMessage, PleaseWaitMessage,} from "../../../../../_metronic/_helpers";
import {apiGetAllListData} from "../../../crud";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {sizePerPageList} from "./InitialStates";


export function GenericTable({reload, tableFilterData, defaultData, columns, url, expandRow, recordCount = 10}) {
    const [dataList, setDataList] = useState(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(recordCount);
    const [page, setPage] = useState(1);

    useEffect(() => {

        if (tableFilterData === 0) {
            tableFilterData = {};
        }

        setIsLoading(true);
        tableFilterData.page = page;
        tableFilterData.sizePerPage = sizePerPage;
        tableFilterData.currentIndex = 0;

        apiGetAllListData(url, tableFilterData).then(({data}) => {
            setDataList(data);
            setIsLoading(false);
            setPage(1);
        })
            .catch(() => {
                setIsLoading(false);
            });
    }, [reload, tableFilterData]);

    const handleTableChange = (filterData, newState) => {
        filterData = newState.filters;

        if (tableFilterData === 0) {
            tableFilterData = {};
        }

        filterData.sizePerPage = newState.sizePerPage;

        if (filterData.page === undefined) {
            filterData.page = newState.page;
        }

        if (filterData.page === undefined) {
            filterData.page = 1;
        }

        if (filterData.sizePerPage === undefined) {
            filterData.sizePerPage = newState.sizePerPage;
        }

        const currentIndex = (filterData.page - 1) * filterData.sizePerPage;

        setIsLoading(true);
        tableFilterData.page = filterData.page;
        tableFilterData.sizePerPage = filterData.sizePerPage;
        tableFilterData.currentIndex = currentIndex;
        tableFilterData.sortOrder = newState.sortOrder;
        tableFilterData.sortField = newState.sortField;

        apiGetAllListData(url, tableFilterData).then().then(({data}) => {
            setDataList(data);
            setSizePerPage(filterData.sizePerPage);
            setIsLoading(false);
            setPage(filterData.page);
        })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const paginationOptions = {
        custom: true,
        totalSize: dataList.totalCount,
        sizePerPageList: sizePerPageList,
        sizePerPage: sizePerPage,
        page: page,
    };

    return (<>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({paginationProps, paginationTableProps}) => {
                    return (<Pagination
                            isLoading={isLoading}
                            paginationProps={paginationProps}>
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center"
                                bootstrap4
                                remote
                                keyField="id"
                                data={dataList.entities === null ? [] : dataList.entities}
                                columns={columns}
                                onTableChange={handleTableChange}
                                expandRow={expandRow}
                                {...paginationTableProps}>
                                <PleaseWaitMessage entities={dataList.entities}/>
                                <NoRecordsFoundMessage entities={dataList.entities}/>
                            </BootstrapTable>
                        </Pagination>);
                }}
            </PaginationProvider>
        </>);
}
