import React, {useContext, useEffect} from "react";
import {Card, CardBody,} from "../../../_metronic/_partials/controls";
import {AuthContext} from "../Common/auth/context/AuthState";
import {defaultList} from "../Common/generic/table/InitialStates";
import {headerSortingClasses, sortCaret} from "../../../_metronic/_helpers";
import {GenericTable} from "../Common/generic/table/GenericTable";
import {HrefColumnFormatter} from "../Common/column-formatters/HrefColumnFormatter";
import {CHART_REPORTS} from "../crud";

export function ReportsDevicesListCard({history, reload, reportId, selectedWeek, columns, donutClickData = 0, title }) {

    const {auth, setTitleButtonGotoCallback} = useContext(AuthContext);


    useEffect(() => {
        setTitleButtonGotoCallback(null, "");
    }, [auth]);

    return (<Card>

        <CardBody>

            <div className="bg-white p-4">
                <b>{title}</b>
            </div>

            <GenericTable reload={reload}
                          tableFilterData={{reportId: reportId, selectedWeek: selectedWeek, filter: donutClickData}}
                          defaultData={defaultList}
                          columns={columns}
                          url={CHART_REPORTS + "/listData"}
            />
        </CardBody>
    </Card>);
}
