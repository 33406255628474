import React from 'react';
import Chart from 'react-apexcharts';

export function DashboardDonutChartCard({ title, data, onDonutClick }) {

    // Check if data and labels are valid
    if (!data || !data.labels || !data.data) {
        console.error('Invalid data or labels');
        return null; // or some error component
    }

    // Define your series names
    const seriesNames = data.labels;

    const options = {
        series: data.data,
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    // Check if index is within the array range
                    if (config.dataPointIndex >= 0 && config.dataPointIndex < seriesNames.length) {
                        const seriesName = seriesNames[config.dataPointIndex];
                        // Call the passed function with the series name
                        if (typeof onDonutClick === 'function') {
                            onDonutClick(seriesName);
                        } else {
                            console.error('onDonutClick is not a function');
                        }
                    } else {
                        console.error('Invalid dataPointIndex:', config.dataPointIndex);
                    }
                }
            }
        },
        labels: seriesNames,
        title: {
            text: title,
            align: 'left'
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            showAlways: true,
                            show: true
                        }
                    }
                }
            }
        }
    };

    return (
        <div className="dashboard-donut-chart-card">
            <Chart
                options={options}
                series={options.series}
                type="donut"
                width="100%"
            />

            <div className="chart-footer"
                 style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px'}}>
                <a href="#" onClick={()=>onDonutClick("All Devices")}>View All Devices &gt;</a>
            </div>
        </div>
    );
}
